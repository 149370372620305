export const tankData ={
    "SW BT 1" : {
        port:[
    {sounding:0, pct:0.000, volume:0.000, wght:0.00,  lcg:4.620, tcg:7.400, vcg:0.000, fsml:0.000, fsmt:0.000},
    {sounding:100, pct:0.001, volume:0.383, wght:0.39,  lcg:4.620, tcg:7.400, vcg:0.054, fsml:0.074, fsmt:50.626},
    {sounding:200, pct:0.003, volume:0.927, wght:0.95, lcg:4.580, tcg:7.399, vcg:0.111, fsml:0.161, fsmt:65.079},
    {sounding:300, pct:0.004, volume:1.612, wght:1.65, lcg:4.541, tcg:7.400, vcg:0.171, fsml:0.304, fsmt:81.038},
    {sounding:400, pct:0.007, volume:2.440, wght:2.50, lcg:4.501, tcg:7.400, vcg:0.232, fsml:0.508, fsmt:96.211},
    {sounding:500, pct:0.009, volume:3.408, wght:3.49, lcg:4.461, tcg:7.400, vcg:0.294, fsml:0.789, fsmt:111.41},
    {sounding:600, pct:0.012, volume:4.519, wght:4.63, lcg:4.421, tcg:7.400, vcg:0.358, fsml:1.157, fsmt:126.388},
    {sounding:700, pct:0.016, volume:5.772, wght:5.92, lcg:4.380, tcg:7.400, vcg:0.421, fsml:1.628, fsmt:141.823},
    {sounding:800, pct:0.019, volume:7.167, wght:7.35, lcg:4.338, tcg:7.400, vcg:0.485, fsml:2.208, fsmt:156.998},
    {sounding:900, pct:0.023, volume:8.703, wght:8.92, lcg:4.297, tcg:7.400, vcg:0.550, fsml:2.914, fsmt:172.207},
    {sounding:1000, pct:0.028, volume:10.381, wght:10.64, lcg:4.255, tcg:7.400, vcg:0.615, fsml:3.732, fmst:186.183},
    {sounding:1100, pct:0.033, volume:12.201, wght:12.51, lcg:4.213, tcg:7.400, vcg:0.680, fsml:4.746, fmst:202.609},
    {sounding:1200, pct:0.038, volume:14.163, wght:14.52, lcg:4.171, tcg:7.400, vcg:0.745, fsml:5.894, fmst:217.788},
    {sounding:1300, pct:0.044, volume:16.267, wght:16.67, lcg:4.129, tcg:7.400, vcg:0.810, fsml:7.217, fmst:232.997},
    {sounding:1400, pct:0.050, volume:18.512, wght:18.98, lcg:4.087, tcg:7.401, vcg:0.876, fsml:8.585, fmst:244.863},
    {sounding:1500, pct:0.056, volume:20.901, wght:21.42, lcg:4.045, tcg:7.401, vcg:0.942, fsml:16.944, fmst:1130.251},
    {sounding:1600, pct:0.067, volume:24.993, wght:25.62, lcg:3.982, tcg:7.974, vcg:1.041, fsml:20.047, fmst:1195.410},
    {sounding:1700, pct:0.079, volume:29.333, wght:30.07, lcg:3.924, tcg:8.407, vcg:1.131, fsml:23.512, fmst:1260.674},
    {sounding:1800, pct:0.091, volume:33.904, wght:34.75, lcg:3.870, tcg:8.743, vcg:1.215, fsml:27.341, fmst:1325.139},
    {sounding:1900, pct:0.104, volume:38.706, wght:39.67, lcg:3.819, tcg:9.010, vcg:1.294, fsml:31.590, fmst:1391.087},
    {sounding:2000, pct:0.118, volume:43.737, wght:44.83, lcg:3.770, tcg:9.228, vcg:1.369, fsml:36.242, fmst:1456.264},
    {sounding:2100, pct:0.132, volume:49.000, wght:50.22, lcg:3.723, tcg:9.407, vcg:1.442, fsml:41.333, fmst:1521.393},
    {sounding:2200, pct:0.147, volume:54.493, wght:55.86, lcg:3.677, tcg:9.557, vcg:1.514, fsml:46.690, fmst:1580.101},
    {sounding:2300, pct:0.162, volume:60.216, wght:61.72, lcg:3.632, tcg:9.685, vcg:1.584, fsml:52.900, fmst:1651.923},
    {sounding:2400, pct:0.179, volume:66.170, wght:67.82, lcg:3.588, tcg:9.794, vcg:1.653, fsml:59.415, fmst:1717.123},
    {sounding:2500, pct:0.195, volume:72.355, wght:74.16, lcg:3.544, tcg:9.889, vcg:1.721, fsml:66.413, fmst:1781.405},
    {sounding:2600, pct:0.213, volume:78.770, wght:80.74, lcg:3.501, tcg:9.971, vcg:1.788, fsml:74.020, fmst:1847.650},
    {sounding:2700, pct:0.230, volume:85.415, wght:87.55, lcg:3.458, tcg:10.043, vcg:1.855, fsml:82.124, fmst:1912.760},
    {sounding:2800, pct:0.249, volume:92.291, wght:94.60, lcg:3.415, tcg:10.107, vcg:1.922, fsml:90.814, fmst:1977.981},
    {sounding:2900, pct:0.268, volume:99.397, wght:101.88, lcg:3.372, tcg:10.164, vcg:1.988, fsml:99.944, fsmt:2040.184},
    {sounding:3000, pct:0.288, volume:106.734, wght:109.40, lcg:3.329, tcg:10.214, vcg:2.055, fsml:110.002, fsmt:2108.486},
    {sounding:3100, pct:0.308, volume:114.301, wght:117.16, lcg:3.287, tcg:10.260, vcg:2.120, fsml:120.510, fsmt:2173.596},
    {sounding:3200, pct:0.329, volume:122.099, wght:125.15, lcg:3.245, tcg:10.301, vcg:2.186, fsml:131.691, fsmt:2238.839},
    {sounding:3300, pct:0.351, volume:130.127, wght:133.38, lcg:3.203, tcg:10.338, vcg:2.252, fsml:143.075, fsmt:2297.460},
    {sounding:3400, pct:0.373, volume:138.386, wght:141.85, lcg:3.160, tcg:10.371, vcg:2.317, fsml:156.091, fsmt:2369.359},
    {sounding:3500, pct:0.396, volume:146.876, wght:150.55, lcg:3.118, tcg:10.402, vcg:2.383, fsml:169.341, fsmt:2434.583},
    {sounding:3600, pct:0.420, volume:155.481, wght:159.37, lcg:3.079, tcg:10.429, vcg:2.448, fsml:169.344, fsmt:2434.597},
    {sounding:3700, pct:0.443, volume:164.086, wght:168.19, lcg:3.043, tcg:10.454, vcg:2.511, fsml:169.344, fsmt:2434.597},
    {sounding:3800, pct:0.466, volume:172.691, wght:177.01, lcg:3.011, tcg:10.476, vcg:2.572, fsml:169.344, fsmt:2434.597},
    {sounding:3900, pct:0.489, volume:181.296, wght:185.83, lcg:2.982, tcg:10.496, vcg:2.633, fsml:169.344, fsmt:2434.599},
    {sounding:4000, pct:0.512, volume:189.900, wght:194.65, lcg:2.956, tcg:10.515, vcg:2.693, fsml:169.344, fsmt:2434.599},
    {sounding:4100, pct:0.536, volume:198.505, wght:203.47, lcg:2.931, tcg:10.531, vcg:2.751, fsml:169.344, fsmt:2434.599},
    {sounding:4200, pct:0.559, volume:207.110, wght:212.29, lcg:2.909, tcg:10.547, vcg:2.810, fsml:169.344, fsmt:2434.598},
    {sounding:4300, pct:0.582, volume:215.715, wght:221.11, lcg:2.889, tcg:10.561, vcg:2.867, fsml:169.344, fsmt:2434.598},
    {sounding:4400, pct:0.605, volume:224.320, wght:229.93, lcg:2.870, tcg:10.574, vcg:2.924, fsml:169.344, fsmt:2434.598},
    {sounding:4500, pct:0.629, volume:232.925, wght:238.75, lcg:2.853, tcg:10.586, vcg:2.980, fsml:169.344, fsmt:2434.600},
    {sounding:4600, pct:0.652, volume:241.530, wght:247.57, lcg:2.837, tcg:10.597, vcg:3.036, fsml:169.344, fsmt:2434.600},
    {sounding:4700, pct:0.675, volume:250.135, wght:256.39, lcg:2.822, tcg:10.607, vcg:3.092, fsml:169.344, fsmt:2434.600},
    {sounding:4800, pct:0.698, volume:258.740, wght:265.21, lcg:2.808, tcg:10.617, vcg:3.147, fsml:169.344, fsmt:2434.601},
    {sounding:4900, pct:0.721, volume:267.344, wght:274.03, lcg:2.795, tcg:10.626, vcg:3.202, fsml:169.344, fsmt:2434.601},
    {sounding:5000, pct:0.745, volume:275.949, wght:282.85, lcg:2.782, tcg:10.635, vcg:3.256, fsml:169.344, fsmt:2434.601},
    {sounding:5100, pct:0.768, volume:284.554, wght:291.67, lcg:2.771, tcg:10.643, vcg:3.310, fsml:169.344, fsmt:2434.601},
    {sounding:5200, pct:0.791, volume:293.159, wght:300.49, lcg:2.760, tcg:10.650, vcg:3.364, fsml:169.344, fsmt:2434.599},
    {sounding:5300, pct:0.814, volume:301.764, wght:309.31, lcg:2.750, tcg:10.657, vcg:3.418, fsml:169.344, fsmt:2434.598},
    {sounding:5400, pct:0.837, volume:310.369, wght:318.13, lcg:2.740, tcg:10.664, vcg:3.472, fsml:169.344, fsmt:2434.601},
    {sounding:5500, pct:0.861, volume:318.974, wght:326.95, lcg:2.731, tcg:10.671, vcg:3.525, fsml:169.344, fsmt:2434.600},
    {sounding:5600, pct:0.884, volume:327.579, wght:335.77, lcg:2.722, tcg:10.677, vcg:3.578, fsml:169.344, fsmt:2434.600},
    {sounding:5700, pct:0.907, volume:336.184, wght:344.59, lcg:2.714, tcg:10.682, vcg:3.631, fsml:169.344, fsmt:2434.599},
    {sounding:5800, pct:0.930, volume:344.788, wght:353.41, lcg:2.706, tcg:10.688, vcg:3.684, fsml:169.344, fsmt:2434.600},
    {sounding:5900, pct:0.954, volume:353.393, wght:362.23, lcg:2.699, tcg:10.693, vcg:3.737, fsml:169.344, fsmt:2434.601},
    {sounding:6000, pct:0.977, volume:361.998, wght:371.05, lcg:2.691, tcg:10.698, vcg:3.790, fsml:169.344, fsmt:2434.601},
    {sounding:6100, pct:1.000, volume:370.600, wght:379.87, lcg:2.685, tcg:10.703, vcg:3.842, fsml:0.000, fsmt:0.000}
   ],
   starboard: [
    {sounding:0, pct:0.000, volume:0.000, wght:0.00,  lcg:4.620, tcg:-7.400, vcg:0.000, fsml:0.000, fsmt:0.000},
    {sounding:100, pct:0.001, volume:0.383, wght:0.39,  lcg:4.620, tcg:-7.400, vcg:0.054, fsml:0.074, fsmt:50.626},
    {sounding:200, pct:0.003, volume:0.927, wght:0.95, lcg:4.580, tcg:7.400, vcg:0.111, fsml:0.163, fsmt:65.836},
    {sounding:300, pct:0.004, volume:1.612, wght:1.65, lcg:4.541, tcg:7.400, vcg:0.171, fsml:0.304, fsmt:81.038},
    {sounding:400, pct:0.007, volume:2.440, wght:2.50, lcg:4.501, tcg:7.400, vcg:0.232, fsml:0.508, fsmt:96.211},
    {sounding:500, pct:0.009, volume:3.408, wght:3.49, lcg:4.461, tcg:7.400, vcg:0.294, fsml:0.789, fsmt:111.417},
    {sounding:600, pct:0.012, volume:4.519, wght:4.63, lcg:4.421, tcg:7.400, vcg:0.358, fsml:1.159, fsmt:126.626},
    {sounding:700, pct:0.016, volume:5.772, wght:5.92, lcg:4.380, tcg:7.400, vcg:0.421, fsml:1.628, fsmt:141.823},
    {sounding:800, pct:0.019, volume:7.167, wght:7.35, lcg:4.338, tcg:7.400, vcg:0.485, fsml:2.208, fsmt:156.998},
    {sounding:900, pct:0.023, volume:8.703, wght:8.92, lcg:4.297, tcg:7.400, vcg:0.550, fsml:2.914, fsmt:172.207},
    {sounding:1000, pct:0.028, volume:10.381, wght:10.64, lcg:4.255, tcg:7.400, vcg:0.615, fsml:3.756, fsmt:187.416},
    {sounding:1100, pct:0.033, volume:12.201, wght:12.51, lcg:4.213, tcg:7.400, vcg:0.680, fsml:4.746, fsmt:202.609},
    {sounding:1200, pct:0.038, volume:14.163, wght:14.52, lcg:4.171, tcg:7.400, vcg:0.745, fsml:5.894, fsmt:217.788},
    {sounding:1300, pct:0.044, volume:16.267, wght:16.67, lcg:4.129, tcg:7.400, vcg:0.810, fsml:7.217, fsmt:232.997},
    {sounding:1400, pct:0.050, volume:18.512, wght:18.98, lcg:4.087, tcg:7.400, vcg:0.876, fsml:8.725, fsmt:248.206},
    {sounding:1500, pct:0.056, volume:20.901, wght:21.42, lcg:4.045, tcg:7.401, vcg:0.942, fsml:16.944, fsmt:1130.251},
    {sounding:1600, pct:0.067, volume:24.993, wght:25.62, lcg:3.982, tcg:7.974, vcg:1.041, fsml:20.047, fsmt:1195.410},
    {sounding:1700, pct:0.079, volume:29.333, wght:30.07, lcg:3.924, tcg:8.407, vcg:1.131, fsml:23.512, fsmt:1260.673},
    {sounding:1800, pct:0.091, volume:33.904, wght:34.75, lcg:3.870, tcg:8.743, vcg:1.215, fsml:27.356, fsmt:1325.937},
    {sounding:1900, pct:0.104, volume:38.706, wght:39.67, lcg:3.819, tcg:9.010, vcg:1.294, fsml:31.590, fsmt:1391.087},
    {sounding:2000, pct:0.118, volume:43.737, wght:44.83, lcg:3.770, tcg:9.228, vcg:1.369, fsml:36.242, fsmt:1456.264},
    {sounding:2100, pct:0.132, volume:49.000, wght:50.22, lcg:3.723, tcg:9.407, vcg:1.442, fsml:41.336, fsmt:1521.530},
    {sounding:2200, pct:0.147, volume:54.493, wght:55.86, lcg:3.677, tcg:9.557, vcg:1.514, fsml:46.887, fsmt:1586.794},
    {sounding:2300, pct:0.162, volume:60.216, wght:61.72, lcg:3.632, tcg:9.685, vcg:1.584, fsml:52.900, fsmt:1651.923},
    {sounding:2400, pct:0.179, volume:66.170, wght:67.82, lcg:3.588, tcg:9.794, vcg:1.653, fsml:59.415, fsmt:1717.123},
    {sounding:2500, pct:0.195, volume:72.355, wght:74.16, lcg:3.544, tcg:9.889, vcg:1.721, fsml:66.450, fsmt:1782.387},
    {sounding:2600, pct:0.213, volume:78.770, wght:80.74, lcg:3.501, tcg:9.971, vcg:1.788, fsml:74.020, fsmt:1847.650},
    {sounding:2700, pct:0.230, volume:85.415, wght:87.55, lcg:3.458, tcg:10.04, vcg:1.855, fsml:82.124, fsmt:1912.760},
    {sounding:2800, pct:0.249, volume:92.291, wght:94.60, lcg:3.415, tcg:10.10, vcg:1.922, fsml:90.814, fsmt:1977.981},
    {sounding:2900, pct:0.268, volume:99.397, wght:101.88, lcg:3.372, tcg:10.16, vcg:1.988, fsml:100.104, fsmt:2043.245},
    {sounding:3000, pct:0.288, volume:106.734, wght:109.40, lcg:3.329, tcg:10.21, vcg:2.055, fsml:110.002, fsmt:2108.486},
    {sounding:3100, pct:0.308, volume:114.301, wght:117.16, lcg:3.287, tcg:10.26, vcg:2.120, fsml:120.510, fsmt:2173.596},
    {sounding:3200, pct:0.329, volume:122.099, wght:125.15, lcg:3.245, tcg:10.30, vcg:2.186, fsml:131.691, fsmt:2238.839},
    {sounding:3300, pct:0.351, volume:130.127, wght:133.38, lcg:3.203, tcg:10.33, vcg:2.252, fsml:143.547, fsmt:2304.104},
    {sounding:3400, pct:0.373, volume:138.386, wght:141.85, lcg:3.160, tcg:10.37, vcg:2.317, fsml:156.091, fsmt:2369.357},
    {sounding:3500, pct:0.396, volume:146.876, wght:150.55, lcg:3.118, tcg:10.40, vcg:2.383, fsml:169.341, fsmt:2434.583},
    {sounding:3600, pct:0.420, volume:155.481, wght:159.37, lcg:3.079, tcg:10.42, vcg:2.448, fsml:169.344, fsmt:2434.597},
    {sounding:3700, pct:0.443, volume:164.086, wght:168.19, lcg:3.043, tcg:10.45, vcg:2.511, fsml:169.344, fsmt:2434.599},
    {sounding:3800, pct:0.466, volume:172.691, wght:177.01, lcg:3.011, tcg:10.47, vcg:2.572, fsml:169.344, fsmt:2434.598},
    {sounding:3900, pct:0.489, volume:181.296, wght:185.83, lcg:2.982, tcg:10.49, vcg:2.633, fsml:169.344, fsmt:2434.600},
    {sounding:4000, pct:0.512, volume:189.900, wght:194.65, lcg:2.956, tcg:10.51, vcg:2.693, fsml:169.344, fsmt:2434.600},
    {sounding:4100, pct:0.536, volume:198.505, wght:203.47, lcg:2.931, tcg:10.53, vcg:2.751, fsml:169.344, fsmt:2434.597},
    {sounding:4200, pct:0.559, volume:207.110, wght:212.29, lcg:2.909, tcg:10.54, vcg:2.810, fsml:169.344, fsmt:2434.599},
    {sounding:4300, pct:0.582, volume:215.715, wght:221.11, lcg:2.889, tcg:10.561, vcg:2.867, fsml:169.344, fsmt:2434.598},
    {sounding:4400, pct:0.605, volume:224.320, wght:229.93, lcg:2.870, tcg:10.574, vcg:2.924, fsml:169.344, fsmt:2434.598},
    {sounding:4500, pct:0.629, volume:232.925, wght:238.75, lcg:2.853, tcg:10.586, vcg:2.980, fsml:169.344, fsmt:2434.601},
    {sounding:4600, pct:0.652, volume:241.530, wght:247.57, lcg:2.837, tcg:10.597, vcg:3.036, fsml:169.344, fsmt:2434.598},
    {sounding:4700, pct:0.675, volume:250.135, wght:256.39, lcg:2.822, tcg:10.607, vcg:3.092, fsml:169.344, fsmt:2434.598},
    {sounding:4800, pct:0.698, volume:258.740, wght:265.21, lcg:2.808, tcg:10.617, vcg:3.147, fsml:169.344, fsmt:2434.600},
    {sounding:4900, pct:0.721, volume:267.344, wght:274.03, lcg:2.795, tcg:10.626, vcg:3.202, fsml:169.344, fsmt:2434.599},
    {sounding:5000, pct:0.745, volume:275.949, wght:282.85, lcg:2.782, tcg:10.635, vcg:3.256, fsml:169.344, fsmt:2434.600},
    {sounding:5100, pct:0.768, volume:284.554, wght:291.67, lcg:2.771, tcg:10.643, vcg:3.310, fsml:169.344, fsmt:2434.602},
    {sounding:5200, pct:0.791, volume:293.159, wght:300.49, lcg:2.760, tcg:10.650, vcg:3.364, fsml:169.344, fsmt:2434.598},
    {sounding:5300, pct:0.814, volume:301.764, wght:309.31, lcg:2.750, tcg:10.657, vcg:3.418, fsml:169.344, fsmt:2434.599},
    {sounding:5400, pct:0.837, volume:310.369, wght:318.13, lcg:2.740, tcg:10.664, vcg:3.472, fsml:169.344, fsmt:2434.601},
    {sounding:5500, pct:0.861, volume:318.974, wght:326.95, lcg:2.731, tcg:10.671, vcg:3.525, fsml:169.344, fsmt:2434.600},
    {sounding:5600, pct:0.884, volume:327.579, wght:335.77, lcg:2.722, tcg:10.677, vcg:3.578, fsml:169.344, fsmt:2434.603},
    {sounding:5700, pct:0.907, volume:336.184, wght:344.59, lcg:2.714, tcg:10.682, vcg:3.631, fsml:169.344, fsmt:2434.604},
    {sounding:5800, pct:0.930, volume:344.788, wght:353.41, lcg:2.706, tcg:10.688, vcg:3.684, fsml:169.344, fsmt:2434.599},
    {sounding:5900, pct:0.954, volume:353.393, wght:362.23, lcg:2.699, tcg:10.693, vcg:3.737, fsml:169.344, fsmt:2434.602},
    {sounding:6000, pct:0.977, volume:361.998, wght:371.05, lcg:2.691, tcg:10.698, vcg:3.790, fsml:169.344, fsmt:2434.603},
    {sounding:6100, pct:1.000, volume:370.600, wght:379.86, lcg:2.685, tcg:10.703, vcg:3.842, fsml:0.000, fsmt:0.000}
  ]
 }, 
 "SW BT 2": {
    port: [
        {sounding:0, pct:0.000, volume:0.000, wght:0.00,  lcg:42.600, tcg:16.146, vcg:0.000, fsml:0.000, fsmt:0.000},
        {sounding:100, pct:0.039, volume:8.179, wght:8.38, lcg:42.600, tcg:16.146, vcg:0.050, fsml:1244.951, fsmt:295.786},
        {sounding:200, pct:0.079, volume:16.659, wght:17.08, lcg:42.600, tcg:16.205, vcg:0.101, fsml:1276.747, fsmt:319.026},
        {sounding:300, pct:0.119, volume:25.316, wght:25.95, lcg:42.600, tcg:16.247, vcg:0.152, fsml:1298.950, fsmt:335.978},
        {sounding:400, pct:0.161, volume:34.103, wght:34.96, lcg:42.600, tcg:16.281, vcg:0.203, fsml:1315.527, fsmt:348.956},
        {sounding:500, pct:0.203, volume:42.987, wght:44.06, lcg:42.600, tcg:16.309, vcg:0.254, fsml:1328.358, fsmt:359.275},
        {sounding:600, pct:0.245, volume:51.948, wght:53.25, lcg:42.600, tcg:16.333, vcg:0.305, fsml:1338.147, fsmt:367.318},
        {sounding:700, pct:0.288, volume:60.965, wght:62.49, lcg:42.600, tcg:16.353, vcg:0.356, fsml:1345.357, fsmt:373.337},
        {sounding:800, pct:0.330, volume:70.023, wght:71.77, lcg:42.600, tcg:16.370, vcg:0.407, fsml:1350.468, fsmt:377.535},
        {sounding:900, pct:0.373, volume:79.108, wght:81.09, lcg:42.600, tcg:16.384, vcg:0.458, fsml:1353.419, fsmt:380.073},
        {sounding:1000, pct:0.416, volume:88.206, wght:90.41, lcg:42.600, tcg:16.396, vcg:0.509, fsml:1354.476, fsmt:380.929},
        {sounding:1100, pct:0.459, volume:97.307, wght:99.74, lcg:42.600, tcg:16.406, vcg:0.559, fsml:1354.483, fsmt:380.933},
        {sounding:1200, pct:0.502, volume:106.408, wght:109.07, lcg:42.600, tcg:16.414, vcg:0.610, fsml:1354.483, fsmt:380.933},
        {sounding:1300, pct:0.545, volume:115.509, wght:118.40, lcg:42.600, tcg:16.420, vcg:0.660, fsml:1354.483, fsmt:380.933},
        {sounding:1400, pct:0.588, volume:124.611, wght:127.73, lcg:42.600, tcg:16.426, vcg:0.711, fsml:1354.483, fsmt:380.933},
        {sounding:1500, pct:0.631, volume:133.706, wght:137.05, lcg:42.600, tcg:16.431, vcg:0.761, fsml:232.200, fsmt:1.918},
        {sounding:1600, pct:0.639, volume:135.408, wght:138.79, lcg:42.606, tcg:16.468, vcg:0.771, fsml:271.765, fsmt:3.042},
        {sounding:1700, pct:0.647, volume:137.110, wght:140.54, lcg:42.611, tcg:16.503, vcg:0.782, fsml:271.765, fsmt:3.042},
        {sounding:1800, pct:0.655, volume:138.812, wght:142.28, lcg:42.617, tcg:16.538, vcg:0.794, fsml:271.765, fsmt:3.042},
        {sounding:1900, pct:0.663, volume:140.514, wght:144.03, lcg:42.622, tcg:16.572, vcg:0.806, fsml:271.765, fsmt:3.042},
        {sounding:2000, pct:0.671, volume:142.216, wght:145.77, lcg:42.627, tcg:16.604, vcg:0.820, fsml:271.765, fsmt:3.042},
        {sounding:2100, pct:0.679, volume:143.918, wght:147.52, lcg:42.632, tcg:16.637, vcg:0.835, fsml:271.765, fsmt:3.042},
        {sounding:2200, pct:0.687, volume:145.620, wght:149.26, lcg:42.637, tcg:16.668, vcg:0.850, fsml:271.765, fsmt:3.042},
        {sounding:2300, pct:0.695, volume:147.322, wght:151.01, lcg:42.642, tcg:16.699, vcg:0.866, fsml:271.765, fsmt:3.042},
        {sounding:2400, pct:0.703, volume:149.024, wght:152.75, lcg:42.646, tcg:16.729, vcg:0.883, fsml:271.765, fsmt:3.042},
        {sounding:2500, pct:0.711, volume:150.726, wght:154.49, lcg:42.651, tcg:16.758, vcg:0.901, fsml:271.765, fsmt:3.042},
        {sounding:2600, pct:0.719, volume:152.429, wght:156.24, lcg:42.655, tcg:16.787, vcg:0.919, fsml:271.765, fsmt:3.042},
        {sounding:2700, pct:0.727, volume:154.131, wght:157.98, lcg:42.660, tcg:16.815, vcg:0.938, fsml:271.765, fsmt:3.042},
        {sounding:2800, pct:0.735, volume:155.833, wght:159.73, lcg:42.664, tcg:16.842, vcg:0.958, fsml:271.765, fsmt:3.042},
        {sounding:2900, pct:0.743, volume:157.535, wght:161.47, lcg:42.668, tcg:16.869, vcg:0.979, fsml:271.765, fsmt:3.042},
        {sounding:3000, pct:0.751, volume:159.237, wght:163.22, lcg:42.672, tcg:16.895, vcg:1.000, fsml:271.765, fsmt:3.042},
        {sounding:3100, pct:0.759, volume:160.939, wght:164.96, lcg:42.676, tcg:16.921, vcg:1.021, fsml:271.765, fsmt:3.042},
        {sounding:3200, pct:0.767, volume:162.641, wght:166.71, lcg:42.680, tcg:16.946, vcg:1.044, fsml:271.765, fsmt:3.042},
        {sounding:3300, pct:0.775, volume:164.343, wght:168.45, lcg:42.684, tcg:16.971, vcg:1.067, fsml:271.765, fsmt:3.042},
        {sounding:3400, pct:0.783, volume:166.045, wght:170.20, lcg:42.688, tcg:16.995, vcg:1.090, fsml:271.765, fsmt:3.042},
        {sounding:3500, pct:0.791, volume:167.747, wght:171.94, lcg:42.691, tcg:17.019, vcg:1.114, fsml:271.765, fsmt:3.042},
        {sounding:3600, pct:0.799, volume:169.449, wght:173.69, lcg:42.695, tcg:17.042, vcg:1.138, fsml:271.765, fsmt:3.042},
        {sounding:3700, pct:0.807, volume:171.151, wght:175.43, lcg:42.699, tcg:17.064, vcg:1.163, fsml:271.765, fsmt:3.042},
        {sounding:3800, pct:0.815, volume:172.853, wght:177.17, lcg:42.702, tcg:17.087, vcg:1.189, fsml:271.765, fsmt:3.042},
        {sounding:3900, pct:0.823, volume:174.555, wght:178.92, lcg:42.705, tcg:17.108, vcg:1.215, fsml:271.765, fsmt:3.042},
        {sounding:4000, pct:0.831, volume:176.257, wght:180.66, lcg:42.709, tcg:17.130, vcg:1.241, fsml:271.765, fsmt:3.042},
        {sounding:4100, pct:0.839, volume:177.959, wght:182.41, lcg:42.712, tcg:17.151, vcg:1.268, fsml:271.765, fsmt:3.042},
        {sounding:4200, pct:0.847, volume:179.661, wght:184.15, lcg:42.715, tcg:17.171, vcg:1.295, fsml:271.765, fsmt:3.042},
        {sounding:4300, pct:0.855, volume:181.363, wght:185.90, lcg:42.718, tcg:17.192, vcg:1.323, fsml:271.765, fsmt:3.042},
        {sounding:4400, pct:0.864, volume:183.065, wght:187.64, lcg:42.721, tcg:17.212, vcg:1.351, fsml:271.765, fsmt:3.042},
        {sounding:4500, pct:0.872, volume:184.768, wght:189.39, lcg:42.724, tcg:17.231, vcg:1.380, fsml:271.765, fsmt:3.042},
        {sounding:4600, pct:0.880, volume:186.470, wght:191.13, lcg:42.727, tcg:17.250, vcg:1.409, fsml:271.765, fsmt:3.042},
        {sounding:4700, pct:0.888, volume:188.172, wght:192.88, lcg:42.730, tcg:17.269, vcg:1.438, fsml:271.765, fsmt:3.042},
        {sounding:4800, pct:0.896, volume:189.874, wght:194.62, lcg:42.733, tcg:17.287, vcg:1.468, fsml:271.765, fsmt:3.042},
        {sounding:4900, pct:0.904, volume:191.576, wght:196.37, lcg:42.736, tcg:17.305, vcg:1.498, fsml:271.765, fsmt:3.042},
        {sounding:5000, pct:0.912, volume:193.278, wght:198.11, lcg:42.739, tcg:17.323, vcg:1.528, fsml:271.765, fsmt:3.042},
        {sounding:5100, pct:0.920, volume:194.980, wght:199.85, lcg:42.742, tcg:17.341, vcg:1.559, fsml:271.765, fsmt:3.042},
        {sounding:5200, pct:0.928, volume:196.682, wght:201.60, lcg:42.744, tcg:17.358, vcg:1.590, fsml:271.765, fsmt:3.042},
        {sounding:5300, pct:0.936, volume:198.384, wght:203.34, lcg:42.747, tcg:17.375, vcg:1.621, fsml:271.765, fsmt:3.042},
        {sounding:5400, pct:0.944, volume:200.086, wght:205.09, lcg:42.749, tcg:17.391, vcg:1.653, fsml:271.765, fsmt:3.042},
        {sounding:5500, pct:0.952, volume:201.788, wght:206.83, lcg:42.752, tcg:17.408, vcg:1.685, fsml:271.765, fsmt:3.042},
        {sounding:5600, pct:0.960, volume:203.490, wght:208.58, lcg:42.754, tcg:17.424, vcg:1.717, fsml:271.765, fsmt:3.042},
        {sounding:5700, pct:0.968, volume:205.192, wght:210.32, lcg:42.757, tcg:17.439, vcg:1.750, fsml:271.765, fsmt:3.042},
        {sounding:5800, pct:0.976, volume:206.894, wght:212.07, lcg:42.759, tcg:17.455, vcg:1.783, fsml:271.765, fsmt:3.042},
        {sounding:5900, pct:0.984, volume:208.596, wght:213.81, lcg:42.762, tcg:17.470, vcg:1.816, fsml:271.765, fsmt:3.042},
        {sounding:6000, pct:0.992, volume:210.298, wght:215.56, lcg:42.764, tcg:17.485, vcg:1.850, fsml:271.765, fsmt:3.042},
        {sounding:6100, pct:1.000, volume:212.000, wght:217.30, lcg:42.766, tcg:17.500, vcg:1.883, fsml:0.000, fsmt:0.000}
    ],
    starboard: [
        {sounding:0, pct:0.000, volume:0.000, wght:0.00,  lcg:42.600, tcg:16.146, vcg:0.000, fsml:0.000, fsmt:0.000},
        {sounding:100, pct:0.039, volume:8.179, wght:8.38, lcg:42.600, tcg:16.146, vcg:0.050, fsml:1244.951, fsmt:295.786},
        {sounding:200, pct:0.079, volume:16.659, wght:17.08, lcg:42.600, tcg:16.205, vcg:0.101, fsml:1276.747, fsmt:319.026},
        {sounding:300, pct:0.119, volume:25.316, wght:25.95, lcg:42.600, tcg:16.247, vcg:0.152, fsml:1298.950, fsmt:335.978},
        {sounding:400, pct:0.161, volume:34.103, wght:34.96, lcg:42.600, tcg:16.281, vcg:0.203, fsml:1315.527, fsmt:348.956},
        {sounding:500, pct:0.203, volume:42.987, wght:44.06, lcg:42.600, tcg:16.309, vcg:0.254, fsml:1328.358, fsmt:359.275},
        {sounding:600, pct:0.245, volume:51.948, wght:53.25, lcg:42.600, tcg:16.333, vcg:0.305, fsml:1338.147, fsmt:367.318},
        {sounding:700, pct:0.288, volume:60.965, wght:62.49, lcg:42.600, tcg:16.353, vcg:0.356, fsml:1345.356, fsmt:373.337},
        {sounding:800, pct:0.330, volume:70.023, wght:71.77, lcg:42.600, tcg:16.370, vcg:0.407, fsml:1350.468, fsmt:377.535},
        {sounding:900, pct:0.373, volume:79.108, wght:81.09, lcg:42.600, tcg:16.384, vcg:0.458, fsml:1353.419, fsmt:380.073},
        {sounding:1000, pct:0.416, volume:88.206, wght:90.41, lcg:42.600, tcg:16.396, vcg:0.509, fsml:1354.476, fsmt:380.929},
        {sounding:1100, pct:0.459, volume:97.307, wght:99.74, lcg:42.600, tcg:16.406, vcg:0.559, fsml:1354.483, fsmt:380.933},
        {sounding:1200, pct:0.502, volume:106.408, wght:109.07, lcg:42.600, tcg:16.414, vcg:0.610, fsml:1354.483, fsmt:380.933},
        {sounding:1300, pct:0.545, volume:115.509, wght:118.40, lcg:42.600, tcg:16.420, vcg:0.660, fsml:1354.483, fsmt:380.933},
        {sounding:1400, pct:0.588, volume:124.611, wght:127.73, lcg:42.600, tcg:16.426, vcg:0.711, fsml:1354.483, fsmt:380.933},
        {sounding:1500, pct:0.631, volume:133.706, wght:137.05, lcg:42.600, tcg:16.431, vcg:0.761, fsml:232.200, fsmt:1.918},
        {sounding:1600, pct:0.639, volume:135.408, wght:138.79, lcg:42.606, tcg:16.468, vcg:0.771, fsml:271.765, fsmt:3.042},
        {sounding:1700, pct:0.647, volume:137.110, wght:140.54, lcg:42.611, tcg:16.503, vcg:0.782, fsml:271.765, fsmt:3.042},
        {sounding:1800, pct:0.655, volume:138.812, wght:142.28, lcg:42.617, tcg:16.538, vcg:0.794, fsml:271.765, fsmt:3.042},
        {sounding:1900, pct:0.663, volume:140.514, wght:144.03, lcg:42.622, tcg:16.572, vcg:0.806, fsml:271.765, fsmt:3.042},
        {sounding:2000, pct:0.671, volume:142.216, wght:145.77, lcg:42.627, tcg:16.604, vcg:0.820, fsml:271.765, fsmt:3.042},
        {sounding:2100, pct:0.679, volume:143.918, wght:147.52, lcg:42.632, tcg:16.637, vcg:0.835, fsml:271.765, fsmt:3.042},
        {sounding:2200, pct:0.687, volume:145.620, wght:149.26, lcg:42.637, tcg:16.668, vcg:0.850, fsml:271.765, fsmt:3.042},
        {sounding:2300, pct:0.695, volume:147.322, wght:151.01, lcg:42.642, tcg:16.699, vcg:0.866, fsml:271.765, fsmt:3.042},
        {sounding:2400, pct:0.703, volume:149.024, wght:152.75, lcg:42.646, tcg:16.729, vcg:0.883, fsml:271.765, fsmt:3.042},
        {sounding:2500, pct:0.711, volume:150.726, wght:154.49, lcg:42.651, tcg:16.758, vcg:0.901, fsml:271.765, fsmt:3.042},
        {sounding:2600, pct:0.719, volume:152.428, wght:156.24, lcg:42.655, tcg:16.787, vcg:0.919, fsml:271.765, fsmt:3.042},
        {sounding:2700, pct:0.727, volume:154.131, wght:157.98, lcg:42.660, tcg:16.815, vcg:0.938, fsml:271.765, fsmt:3.042},
        {sounding:2800, pct:0.735, volume:155.833, wght:159.73, lcg:42.664, tcg:16.842, vcg:0.958, fsml:271.765, fsmt:3.042},
        {sounding:2900, pct:0.743, volume:157.535, wght:161.47, lcg:42.668, tcg:16.869, vcg:0.979, fsml:271.765, fsmt:3.042},
        {sounding:3000, pct:0.751, volume:159.237, wght:163.22, lcg:42.672, tcg:16.895, vcg:1.000, fsml:271.765, fsmt:3.042},
        {sounding:3100, pct:0.759, volume:160.939, wght:164.96, lcg:42.676, tcg:16.921, vcg:1.021, fsml:271.765, fsmt:3.042},
        {sounding:3200, pct:0.767, volume:162.641, wght:166.71, lcg:42.680, tcg:16.946, vcg:1.044, fsml:271.765, fsmt:3.042},
        {sounding:3300, pct:0.775, volume:164.343, wght:168.45, lcg:42.684, tcg:16.971, vcg:1.067, fsml:271.765, fsmt:3.042},
        {sounding:3400, pct:0.783, volume:166.045, wght:170.20, lcg:42.688, tcg:16.995, vcg:1.090, fsml:271.765, fsmt:3.042},
        {sounding:3500, pct:0.791, volume:167.747, wght:171.94, lcg:42.691, tcg:17.019, vcg:1.114, fsml:271.765, fsmt:3.042},
        {sounding:3600, pct:0.799, volume:169.449, wght:173.69, lcg:42.695, tcg:17.042, vcg:1.138, fsml:271.765, fsmt:3.042},
        {sounding:3700, pct:0.807, volume:171.151, wght:175.43, lcg:42.699, tcg:17.064, vcg:1.163, fsml:271.765, fsmt:3.042},
        {sounding:3800, pct:0.815, volume:172.853, wght:177.17, lcg:42.702, tcg:17.087, vcg:1.189, fsml:271.765, fsmt:3.042},
        {sounding:3900, pct:0.823, volume:174.555, wght:178.92, lcg:42.705, tcg:17.108, vcg:1.215, fsml:271.765, fsmt:3.042},
        {sounding:4000, pct:0.831, volume:176.257, wght:180.66, lcg:42.709, tcg:17.130, vcg:1.241, fsml:271.765, fsmt:3.042},
        {sounding:4100, pct:0.839, volume:177.959, wght:182.41, lcg:42.712, tcg:17.151, vcg:1.268, fsml:271.765, fsmt:3.042},
        {sounding:4200, pct:0.847, volume:179.661, wght:184.15, lcg:42.715, tcg:17.171, vcg:1.295, fsml:271.765, fsmt:3.042},
        {sounding:4300, pct:0.855, volume:181.363, wght:185.90, lcg:42.718, tcg:17.192, vcg:1.323, fsml:271.765, fsmt:3.042},
        {sounding:4400, pct:0.864, volume:183.065, wght:187.64, lcg:42.721, tcg:17.212, vcg:1.351, fsml:271.765, fsmt:3.042},
        {sounding:4500, pct:0.872, volume:184.768, wght:189.39, lcg:42.724, tcg:17.231, vcg:1.380, fsml:271.765, fsmt:3.042},
        {sounding:4600, pct:0.880, volume:186.470, wght:191.13, lcg:42.727, tcg:17.250, vcg:1.409, fsml:271.765, fsmt:3.042},
        {sounding:4700, pct:0.888, volume:188.172, wght:192.88, lcg:42.730, tcg:17.269, vcg:1.438, fsml:271.765, fsmt:3.042},
        {sounding:4800, pct:0.896, volume:189.874, wght:194.62, lcg:42.733, tcg:17.287, vcg:1.468, fsml:271.765, fsmt:3.042},
        {sounding:4900, pct:0.904, volume:191.576, wght:196.37, lcg:42.736, tcg:17.305, vcg:1.498, fsml:271.765, fsmt:3.042},
        {sounding:5000, pct:0.912, volume:193.278, wght:198.11, lcg:42.739, tcg:17.323, vcg:1.528, fsml:271.765, fsmt:3.042},
        {sounding:5100, pct:0.920, volume:194.980, wght:199.85, lcg:42.742, tcg:17.341, vcg:1.559, fsml:271.765, fsmt:3.042},
        {sounding:5200, pct:0.928, volume:196.682, wght:201.60, lcg:42.744, tcg:17.358, vcg:1.590, fsml:271.765, fsmt:3.042},
        {sounding:5300, pct:0.936, volume:198.384, wght:203.34, lcg:42.747, tcg:17.375, vcg:1.621, fsml:271.765, fsmt:3.042},
        {sounding:5400, pct:0.944, volume:200.086, wght:205.09, lcg:42.749, tcg:17.391, vcg:1.653, fsml:271.765, fsmt:3.042},
        {sounding:5500, pct:0.952, volume:201.788, wght:206.83, lcg:42.752, tcg:17.408, vcg:1.685, fsml:271.765, fsmt:3.042},
        {sounding:5600, pct:0.960, volume:203.490, wght:208.58, lcg:42.754, tcg:17.424, vcg:1.717, fsml:271.765, fsmt:3.042},
        {sounding:5700, pct:0.968, volume:205.192, wght:210.32, lcg:42.757, tcg:17.439, vcg:1.750, fsml:271.765, fsmt:3.042},
        {sounding:5800, pct:0.976, volume:206.894, wght:212.07, lcg:42.759, tcg:17.455, vcg:1.783, fsml:271.765, fsmt:3.042},
        {sounding:5900, pct:0.984, volume:208.596, wght:213.81, lcg:42.762, tcg:17.470, vcg:1.816, fsml:271.765, fsmt:3.042},
        {sounding:6000, pct:0.992, volume:210.298, wght:215.56, lcg:42.764, tcg:17.485, vcg:1.850, fsml:271.765, fsmt:3.042},
        {sounding:6100, pct:1.000, volume:212.000, wght:217.30, lcg:42.766, tcg:17.500, vcg:1.883, fsml:0.000, fsmt:0.000}
    ]
 },
 "SW BT 3": {
    port: [
        {sounding:0, pct:0.000, volume:0.000, wght:0.00,  lcg:42.600, tcg:16.146, vcg:0.000, fsml:0.000, fsmt:0.000},
        {sounding:100, pct:0.004, volume:0.896, wght:0.92, lcg:57.057, tcg:16.500, vcg:1.553, fsml:1.905, fsmt:42.652},
        {sounding:200, pct:0.008, volume:2.006, wght:2.06, lcg:57.140, tcg:16.500, vcg:1.607, fsml:3.129, fsmt:50.278},
        {sounding:300, pct:0.013, volume:3.299, wght:3.38, lcg:57.218, tcg:16.500, vcg:1.663, fsml:4.764, fsmt:57.903},
        {sounding:400, pct:0.020, volume:4.773, wght:4.89, lcg:57.296, tcg:16.500, vcg:1.721, fsml:6.897, fsmt:65.529},
        {sounding:500, pct:0.026, volume:6.430, wght:6.59, lcg:57.375, tcg:16.500, vcg:1.780, fsml:9.618, fsmt:73.169},
        {sounding:600, pct:0.034, volume:8.269, wght:8.48, lcg:57.455, tcg:16.500, vcg:1.841, fsml:12.943, fsmt:80.803},
        {sounding:700, pct:0.042, volume:10.291, wght:10.55, lcg:57.536, tcg:16.500, vcg:1.902, fsml:16.964, fsmt:88.429},
        {sounding:800, pct:0.051, volume:12.495, wght:12.81, lcg:57.618, tcg:16.500, vcg:1.963, fsml:21.709, fsmt:96.053},
        {sounding:900, pct:0.061, volume:14.881, wght:15.25, lcg:57.700, tcg:16.500, vcg:2.025, fsml:27.330, fsmt:103.679},
        {sounding:1000, pct:0.071, volume:17.449, wght:17.89 , lcg:57.783, tcg:16.500, vcg:2.088, fsml:33.798, fsmt:111.305},
        {sounding:1100, pct:0.083, volume:20.200, wght:20.70 , lcg:57.866, tcg:16.500, vcg:2.151, fsml:41.207, fsmt:118.929},
        {sounding:1200, pct:0.095, volume:23.132, wght:23.71 , lcg:57.950, tcg:16.500, vcg:2.214, fsml:49.670, fsmt:126.556},
        {sounding:1300, pct:0.107, volume:26.254, wght:26.91 , lcg:58.035, tcg:16.500, vcg:2.278, fsml:521.010, fsmt:277.050},
        {sounding:1400, pct:0.134, volume:32.873, wght:33.70 , lcg:58.673, tcg:16.500, vcg:2.393, fsml:521.010, fsmt:277.050},
        {sounding:1500, pct:0.161, volume:39.492, wght:40.48 , lcg:59.096, tcg:16.500, vcg:2.486, fsml:521.010, fsmt:277.050},
        {sounding:1600, pct:0.188, volume:46.111, wght:47.26 , lcg:59.398, tcg:16.500, vcg:2.567, fsml:521.010, fsmt:277.050},
        {sounding:1700, pct:0.216, volume:52.730, wght:54.05 , lcg:59.624, tcg:16.500, vcg:2.640, fsml:521.010, fsmt:277.050},
        {sounding:1800, pct:0.243, volume:59.349, wght:60.83 , lcg:59.800, tcg:16.500, vcg:2.708, fsml:521.010, fsmt:277.050},
        {sounding:1900, pct:0.270, volume:65.968, wght:67.62 , lcg:59.941, tcg:16.500, vcg:2.773, fsml:521.010, fsmt:277.050},
        {sounding:2000, pct:0.297, volume:72.587, wght:74.40 , lcg:60.055, tcg:16.500, vcg:2.835, fsml:521.010, fsmt:277.050},
        {sounding:2100, pct:0.324, volume:79.206, wght:81.19 , lcg:60.151, tcg:16.500, vcg:2.894, fsml:521.010, fsmt:277.050},
        {sounding:2200, pct:0.351, volume:85.825, wght:87.97 , lcg:60.232, tcg:16.500, vcg:2.953, fsml:521.010, fsmt:277.050},
        {sounding:2300, pct:0.378, volume:92.444, wght:94.76 , lcg:60.301, tcg:16.500, vcg:3.010, fsml:521.010, fsmt:277.050},
        {sounding:2400, pct:0.405, volume:99.063, wght:101.54, lcg:60.361, tcg:16.500, vcg:3.066, fsml:521.010, fsmt:277.050},
        {sounding:2500, pct:0.432, volume:105.682, wght:108.32, lcg:60.414, tcg:16.500, vcg:3.121, fsml:521.010, fsmt:277.050},
        {sounding:2600, pct:0.459, volume:112.301, wght:115.11, lcg:60.460, tcg:16.500, vcg:3.176, fsml:521.010, fsmt:277.050},
        {sounding:2700, pct:0.486, volume:118.920, wght:121.89, lcg:60.501, tcg:16.500, vcg:3.230, fsml:521.010, fsmt:277.050},
        {sounding:2800, pct:0.513, volume:125.539, wght:128.68, lcg:60.538, tcg:16.500, vcg:3.284, fsml:521.010, fsmt:277.050},
        {sounding:2900, pct:0.540, volume:132.158, wght:135.46, lcg:60.571, tcg:16.500, vcg:3.337, fsml:521.010, fsmt:277.050},
        {sounding:3000, pct:0.567, volume:138.777, wght:142.25, lcg:60.601, tcg:16.500, vcg:3.390, fsml:521.010, fsmt:277.050},
        {sounding:3100, pct:0.594, volume:145.396, wght:149.03, lcg:60.629, tcg:16.500, vcg:3.443, fsml:521.010, fsmt:277.050},
        {sounding:3200, pct:0.621, volume:152.015, wght:155.82, lcg:60.653, tcg:16.500, vcg:3.496, fsml:521.010, fsmt:277.050},
        {sounding:3300, pct:0.648, volume:158.634, wght:162.60, lcg:60.676, tcg:16.500, vcg:3.548, fsml:521.010, fsmt:277.050},
        {sounding:3400, pct:0.675, volume:165.253, wght:169.38, lcg:60.697, tcg:16.500, vcg:3.600, fsml:521.010, fsmt:277.050},
        {sounding:3500, pct:0.702, volume:171.873, wght:176.17, lcg:60.717, tcg:16.500, vcg:3.652, fsml:521.010, fsmt:277.050},
        {sounding:3600, pct:0.729, volume:178.491, wght:182.95, lcg:60.735, tcg:16.500, vcg:3.704, fsml:521.010, fsmt:277.050},
        {sounding:3700, pct:0.757, volume:185.110, wght:189.74, lcg:60.751, tcg:16.500, vcg:3.756, fsml:521.010, fsmt:277.050},
        {sounding:3800, pct:0.784, volume:191.730, wght:196.52, lcg:60.767, tcg:16.500, vcg:3.807, fsml:521.010, fsmt:277.050},
        {sounding:3900, pct:0.811, volume:198.349, wght:203.31, lcg:60.781, tcg:16.500, vcg:3.859, fsml:521.010, fsmt:277.050},
        {sounding:4000, pct:0.838, volume:204.967, wght:210.09, lcg:60.795, tcg:16.500, vcg:3.910, fsml:521.010, fsmt:277.050},
        {sounding:4100, pct:0.865, volume:211.587, wght:216.88, lcg:60.807, tcg:16.500, vcg:3.961, fsml:521.010, fsmt:277.050},
        {sounding:4200, pct:0.892, volume:218.206, wght:223.66, lcg:60.819, tcg:16.500, vcg:4.013, fsml:521.010, fsmt:277.050},
        {sounding:4300, pct:0.919, volume:224.825, wght:230.45, lcg:60.830, tcg:16.500, vcg:4.064, fsml:521.010, fsmt:277.050},
        {sounding:4400, pct:0.946, volume:231.444, wght:237.23, lcg:60.841, tcg:16.500, vcg:4.115, fsml:521.010, fsmt:277.050},
        {sounding:4500, pct:0.973, volume:238.063, wght:244.01, lcg:60.851, tcg:16.500, vcg:4.166, fsml:521.010, fsmt:277.050},        
        {sounding:4600, pct:1.000, volume:244.684, wght:250.80, lcg:60.860, tcg:16.500, vcg:4.217, fsml:0.000, fsmt:0.000}

    ],
    starboard: [
        {sounding:0, pct:0.000, volume:0.000, wght:0.00,  lcg:42.600, tcg:16.146, vcg:0.000, fsml:0.000, fsmt:0.000},
        {sounding:100, pct:0.003, volume:0.823, wght:0.84, lcg:57.056, tcg:16.500, vcg:1.557, fsml:1.905, fsmt:42.656},
        {sounding:200, pct:0.008, volume:1.934, wght:1.98, lcg:57.143, tcg:16.500, vcg:1.609, fsml:3.113, fsmt:50.282},
        {sounding:300, pct:0.013, volume:3.226, wght:3.31, lcg:57.221, tcg:16.500, vcg:1.661, fsml:4.758, fsmt:57.908},
        {sounding:400, pct:0.019, volume:4.701, wght:4.82, lcg:57.300, tcg:16.500, vcg:1.716, fsml:6.903, fsmt:65.535},
        {sounding:500, pct:0.026, volume:6.358, wght:6.52, lcg:57.379, tcg:16.500, vcg:1.781, fsml:9.602, fsmt:73.161},
        {sounding:600, pct:0.033, volume:8.197, wght:8.40, lcg:57.459, tcg:16.500, vcg:1.842, fsml:12.933, fsmt:80.787},
        {sounding:700, pct:0.042, volume:10.218, wght:10.47, lcg:57.539, tcg:16.500, vcg:1.901, fsml:16.932, fsmt:88.414},
        {sounding:800, pct:0.051, volume:12.421, wght:12.73, lcg:57.621, tcg:16.500, vcg:1.960, fsml:21.721, fsmt:96.039},
        {sounding:900, pct:0.060, volume:14.808, wght:15.18, lcg:57.703, tcg:16.500, vcg:2.022, fsml:27.551, fsmt:103.944},
        {sounding:1000, pct:0.071, volume:17.395, wght:17.83, lcg:57.789, tcg:16.500, vcg:2.088, fsml:34.999, fsmt:112.587},
        {sounding:1100, pct:0.082, volume:20.188, wght:20.69, lcg:57.877, tcg:16.500, vcg:2.153, fsml:43.664, fsmt:121.231},
        {sounding:1200, pct:0.095, volume:23.188, wght:23.77, lcg:57.967, tcg:16.500, vcg:2.216, fsml:53.667, fsmt:129.873},
        {sounding:1300, pct:0.108, volume:26.395, wght:27.05, lcg:58.060, tcg:16.500, vcg:2.280, fsml:521.024,  fsmt:277.038},
        {sounding:1400, pct:0.135, volume:33.014, wght:33.84, lcg:58.689, tcg:16.500, vcg:2.396, fsml:521.024,  fsmt:277.038},
        {sounding:1500, pct:0.162, volume:39.633, wght:40.62, lcg:59.109, tcg:16.500, vcg:2.489, fsml:521.024,  fsmt:277.038},
        {sounding:1600, pct:0.189, volume:46.252, wght:47.41, lcg:59.408, tcg:16.500, vcg:2.569, fsml:521.024,  fsmt:277.038},
        {sounding:1700, pct:0.216, volume:52.871, wght:54.19, lcg:59.632, tcg:16.500, vcg:2.642, fsml:521.024,  fsmt:277.038},
        {sounding:1800, pct:0.243, volume:59.490, wght:60.98, lcg:59.807, tcg:16.500, vcg:2.710, fsml:521.024,  fsmt:277.038},
        {sounding:1900, pct:0.270, volume:66.109, wght:67.76, lcg:59.946, tcg:16.500, vcg:2.774, fsml:521.024,  fsmt:277.038},
        {sounding:2000, pct:0.297, volume:72.728, wght:74.55, lcg:60.060, tcg:16.500, vcg:2.835, fsml:521.024,  fsmt:277.038},
        {sounding:2100, pct:0.324, volume:79.347, wght:81.33, lcg:60.155, tcg:16.500, vcg:2.895, fsml:521.024,  fsmt:277.038},
        {sounding:2200, pct:0.351, volume:85.966, wght:88.12, lcg:60.236, tcg:16.500, vcg:2.953, fsml:521.024,  fsmt:277.038},
        {sounding:2300, pct:0.378, volume:92.585, wght:94.90, lcg:60.305, tcg:16.500, vcg:3.010, fsml:521.024,  fsmt:277.038},
        {sounding:2400, pct:0.405, volume:99.204, wght:101.68, lcg:60.364, tcg:16.500, vcg:3.066, fsml:521.024,  fsmt:277.038},
        {sounding:2500, pct:0.432, volume:105.823, wght:108.47, lcg:60.417, tcg:16.500, vcg:3.121, fsml:521.024,  fsmt:277.038},
        {sounding:2600, pct:0.459, volume:112.442, wght:115.25, lcg:60.463, tcg:16.500, vcg:3.176, fsml:521.024,  fsmt:277.038},
        {sounding:2700, pct:0.486, volume:119.061, wght:122.04, lcg:60.504, tcg:16.500, vcg:3.230, fsml:521.024,  fsmt:277.038},
        {sounding:2800, pct:0.513, volume:125.680, wght:128.82, lcg:60.541, tcg:16.500, vcg:3.284, fsml:521.024,  fsmt:277.038},
        {sounding:2900, pct:0.540, volume:132.299, wght:135.61, lcg:60.573, tcg:16.500, vcg:3.337, fsml:521.024,  fsmt:277.038},
        {sounding:3000, pct:0.567, volume:138.918, wght:142.39, lcg:60.603, tcg:16.500, vcg:3.390, fsml:521.024,  fsmt:277.038},
        {sounding:3100, pct:0.594, volume:145.537, wght:149.18, lcg:60.630, tcg:16.500, vcg:3.443, fsml:521.024,  fsmt:277.038},
        {sounding:3200, pct:0.621, volume:152.156, wght:155.96, lcg:60.655, tcg:16.500, vcg:3.495, fsml:521.024,  fsmt:277.038},
        {sounding:3300, pct:0.649, volume:158.775, wght:162.74, lcg:60.678, tcg:16.500, vcg:3.548, fsml:521.024,  fsmt:277.038},
        {sounding:3400, pct:0.676, volume:165.394, wght:169.53, lcg:60.699, tcg:16.500, vcg:3.600, fsml:521.024,  fsmt:277.038},
        {sounding:3500, pct:0.703, volume:172.013, wght:176.31, lcg:60.718, tcg:16.500, vcg:3.652, fsml:521.024,  fsmt:277.038},
        {sounding:3600, pct:0.730, volume:178.632, wght:183.10, lcg:60.736, tcg:16.500, vcg:3.704, fsml:521.024,  fsmt:277.038},
        {sounding:3700, pct:0.757, volume:185.251, wght:189.88, lcg:60.753, tcg:16.500, vcg:3.755, fsml:521.024,  fsmt:277.038},
        {sounding:3800, pct:0.784, volume:191.870, wght:196.67, lcg:60.768, tcg:16.500, vcg:3.807, fsml:521.024,  fsmt:277.038},
        {sounding:3900, pct:0.811, volume:198.489, wght:203.45, lcg:60.782, tcg:16.500, vcg:3.858, fsml:521.024,  fsmt:277.038},
        {sounding:4000, pct:0.838, volume:205.108, wght:210.24, lcg:60.796, tcg:16.500, vcg:3.910, fsml:521.024,  fsmt:277.038},
        {sounding:4100, pct:0.865, volume:211.727, wght:217.02, lcg:60.809, tcg:16.500, vcg:3.961, fsml:521.024,  fsmt:277.038},
        {sounding:4200, pct:0.892, volume:218.346, wght:223.80, lcg:60.820, tcg:16.500, vcg:4.012, fsml:521.024,  fsmt:277.038},
        {sounding:4300, pct:0.919, volume:224.965, wght:230.59, lcg:60.832, tcg:16.500, vcg:4.063, fsml:521.024,  fsmt:277.038},
        {sounding:4400, pct:0.946, volume:231.584, wght:237.37, lcg:60.842, tcg:16.500, vcg:4.114, fsml:521.024,  fsmt:277.038},
        {sounding:4500, pct:0.973, volume:238.203, wght:244.16, lcg:60.852, tcg:16.500, vcg:4.165, fsml:521.024,  fsmt:277.038},
        {sounding:4600, pct:1.000, volume:244.822, wght:250.94, lcg:60.861, tcg:16.500, vcg:4.216, fsml:0.000,  fsmt:0.000}
    ]
 },
 "FO Stor. Tk": {
    port: [
        {sounding:0, pct:0.000, volume:0.000, wght:0.00,  lcg:40.200, tcg:15.900, vcg:1.550, fsml:0.000, fsmt:0.000},
        {sounding:100, pct:0.029, volume:4.799, wght:4.03, lcg:40.200, tcg:15.900, vcg:1.550, fsml:236.983, fsmt:113.002},
        {sounding:200, pct:0.059, volume:9.598, wght:8.06, lcg:40.200, tcg:15.900, vcg:1.600, fsml:236.983, fsmt:113.002},
        {sounding:300, pct:0.088, volume:14.396, wght:12.09, lcg:40.200, tcg:15.900, vcg:1.650, fsml:236.983, fsmt:113.002},
        {sounding:400, pct:0.118, volume:19.195, wght:16.12, lcg:40.200, tcg:15.900, vcg:1.700, fsml:236.983, fsmt:113.002},
        {sounding:500, pct:0.147, volume:23.994, wght:20.15, lcg:40.200, tcg:15.900, vcg:1.750, fsml:236.983, fsmt:113.002},
        {sounding:600, pct:0.176, volume:28.793, wght:24.19, lcg:40.200, tcg:15.900, vcg:1.800, fsml:236.983, fsmt:113.002},
        {sounding:700, pct:0.206, volume:33.591, wght:28.22, lcg:40.200, tcg:15.900, vcg:1.850, fsml:236.983, fsmt:113.002},
        {sounding:800, pct:0.235, volume:38.390, wght:32.25, lcg:40.200, tcg:15.900, vcg:1.900, fsml:236.983, fsmt:113.002},
        {sounding:900, pct:0.265, volume:43.189, wght:36.28, lcg:40.200, tcg:15.900, vcg:1.950, fsml:236.983, fsmt:113.002},
        {sounding:1000, pct:0.294, volume:47.988, wght:40.31, lcg:40.200, tcg:15.900, vcg:2.000, fsml:236.983, fsmt:113.002},
        {sounding:1100, pct:0.324, volume:52.786, wght:44.34, lcg:40.200, tcg:15.900, vcg:2.050, fsml:236.983, fsmt:113.002},
        {sounding:1200, pct:0.353, volume:57.585, wght:48.37, lcg:40.200, tcg:15.900, vcg:2.100, fsml:236.983, fsmt:113.002},
        {sounding:1300, pct:0.382, volume:62.384, wght:52.40, lcg:40.200, tcg:15.900, vcg:2.150, fsml:236.983, fsmt:113.002},
        {sounding:1400, pct:0.412, volume:67.183, wght:56.43, lcg:40.200, tcg:15.900, vcg:2.200, fsml:236.983, fsmt:113.002},
        {sounding:1500, pct:0.441, volume:71.982, wght:60.46, lcg:40.200, tcg:15.900, vcg:2.250, fsml:236.983, fsmt:113.002},
        {sounding:1600, pct:0.471, volume:76.780, wght:64.50, lcg:40.200, tcg:15.900, vcg:2.300, fsml:236.983, fsmt:113.002},
        {sounding:1700, pct:0.500, volume:81.579, wght:68.53, lcg:40.200, tcg:15.900, vcg:2.350, fsml:236.983, fsmt:113.002},
        {sounding:1800, pct:0.529, volume:86.378, wght:72.56, lcg:40.200, tcg:15.900, vcg:2.400, fsml:236.983, fsmt:113.002},
        {sounding:1900, pct:0.559, volume:91.177, wght:76.59, lcg:40.200, tcg:15.900, vcg:2.450, fsml:236.983, fsmt:113.002},
        {sounding:2000, pct:0.588, volume:95.975, wght:80.62, lcg:40.200, tcg:15.900, vcg:2.500, fsml:236.983, fsmt:113.002},
        {sounding:2100, pct:0.618, volume:100.774, wght:84.65, lcg:40.200, tcg:15.900, vcg:2.550, fsml:236.983, fsmt:113.002},
        {sounding:2200, pct:0.647, volume:105.573, wght:88.68, lcg:40.200, tcg:15.900, vcg:2.600, fsml:236.983, fsmt:113.002},
        {sounding:2300, pct:0.676, volume:110.372, wght:92.71, lcg:40.200, tcg:15.900, vcg:2.650, fsml:236.983, fsmt:113.002},
        {sounding:2400, pct:0.706, volume:115.170, wght:96.74, lcg:40.200, tcg:15.900, vcg:2.700, fsml:236.983, fsmt:113.002},
        {sounding:2500, pct:0.735, volume:119.969, wght:100.77, lcg:40.200, tcg:15.900, vcg:2.750, fsml:236.983, fsmt:113.002},
        {sounding:2600, pct:0.765, volume:124.768, wght:104.81, lcg:40.200, tcg:15.900, vcg:2.800, fsml:236.983, fsmt:113.002},
        {sounding:2700, pct:0.794, volume:129.567, wght:108.84, lcg:40.200, tcg:15.900, vcg:2.850, fsml:236.983, fsmt:113.002},
        {sounding:2800, pct:0.824, volume:134.366, wght:112.87, lcg:40.200, tcg:15.900, vcg:2.900, fsml:236.983, fsmt:113.002},
        {sounding:2900, pct:0.853, volume:139.164, wght:116.90, lcg:40.200, tcg:15.900, vcg:2.950, fsml:236.983, fsmt:113.002},
        {sounding:3000, pct:0.882, volume:143.963, wght:120.93, lcg:40.200, tcg:15.900, vcg:3.000, fsml:236.983, fsmt:113.002},
        {sounding:3100, pct:0.912, volume:148.762, wght:124.96, lcg:40.200, tcg:15.900, vcg:3.050, fsml:236.983, fsmt:113.002},
        {sounding:3200, pct:0.941, volume:153.561, wght:128.99, lcg:40.200, tcg:15.900, vcg:3.100, fsml:236.983, fsmt:113.002},
        {sounding:3300, pct:0.971, volume:158.359, wght:133.02, lcg:40.200, tcg:15.900, vcg:3.150, fsml:236.983, fsmt:113.002},
        {sounding:3400, pct:1.000, volume:163.160, wght:137.05, lcg:40.200, tcg:15.900, vcg:3.200, fsml:0.000, fsmt:0.000}
    ],
    starboard: [
        {sounding:0, pct:0.000, volume:0.000, wght:0.00,  lcg:41.400, tcg:15.900, vcg:1.550, fsml:0.000, fsmt:0.000},
        {sounding:100, pct:0.029, volume:6.170, wght:5.18, lcg:41.400, tcg:15.900, vcg:1.550, fsml:503.728, fsmt:145.294},
        {sounding:200, pct:0.059, volume:12.340, wght:10.37, lcg:41.400, tcg:15.900, vcg:1.600, fsml:503.728, fsmt:145.294},
        {sounding:300, pct:0.088, volume:18.510, wght:15.55, lcg:41.400, tcg:15.900, vcg:1.650, fsml:503.728, fsmt:145.294},
        {sounding:400, pct:0.118, volume:24.680, wght:20.73, lcg:41.400, tcg:15.900, vcg:1.700, fsml:503.728, fsmt:145.294},
        {sounding:500, pct:0.147, volume:30.849, wght:25.91, lcg:41.400, tcg:15.900, vcg:1.750, fsml:503.728, fsmt:145.294},
        {sounding:600, pct:0.176, volume:37.019, wght:31.10, lcg:41.400, tcg:15.900, vcg:1.800, fsml:503.728, fsmt:145.294},
        {sounding:700, pct:0.206, volume:43.189, wght:36.28, lcg:41.400, tcg:15.900, vcg:1.850, fsml:503.728, fsmt:145.294},
        {sounding:800, pct:0.235, volume:49.359, wght:41.46, lcg:41.400, tcg:15.900, vcg:1.900, fsml:503.728, fsmt:145.294},
        {sounding:900, pct:0.265, volume:55.529, wght:46.64, lcg:41.400, tcg:15.900, vcg:1.950, fsml:503.728, fsmt:145.294},
        {sounding:1000, pct:0.294, volume:61.699, wght:51.83, lcg:41.400, tcg:15.900, vcg:2.000, fsml:503.728, fsmt:145.294},
        {sounding:1100, pct:0.324, volume:67.869, wght:57.01, lcg:41.400, tcg:15.900, vcg:2.050, fsml:503.728, fsmt:145.294},
        {sounding:1200, pct:0.353, volume:74.039, wght:62.19, lcg:41.400, tcg:15.900, vcg:2.100, fsml:503.728, fsmt:145.294},
        {sounding:1300, pct:0.382, volume:80.209, wght:67.38, lcg:41.400, tcg:15.900, vcg:2.150, fsml:503.728, fsmt:145.294},
        {sounding:1400, pct:0.412, volume:86.379, wght:72.56, lcg:41.400, tcg:15.900, vcg:2.200, fsml:503.728, fsmt:145.294},
        {sounding:1500, pct:0.441, volume:92.548, wght:77.74, lcg:41.400, tcg:15.900, vcg:2.250, fsml:503.728, fsmt:145.294},
        {sounding:1600, pct:0.471, volume:98.718, wght:82.92, lcg:41.400, tcg:15.900, vcg:2.300, fsml:503.728, fsmt:145.294},
        {sounding:1700, pct:0.500, volume:104.888, wght:88.11, lcg:41.400, tcg:15.900, vcg:2.350, fsml:503.728, fsmt:145.294},
        {sounding:1800, pct:0.529, volume:111.058, wght:93.29, lcg:41.400, tcg:15.900, vcg:2.400, fsml:503.728, fsmt:145.294},
        {sounding:1900, pct:0.559, volume:117.228, wght:98.47, lcg:41.400, tcg:15.900, vcg:2.450, fsml:503.728, fsmt:145.294},
        {sounding:2000, pct:0.588, volume:123.398, wght:103.65, lcg:41.400, tcg:15.900, vcg:2.500, fsml:503.728, fsmt:145.294},
        {sounding:2100, pct:0.618, volume:129.568, wght:108.84, lcg:41.400, tcg:15.900, vcg:2.550, fsml:503.728, fsmt:145.294},
        {sounding:2200, pct:0.647, volume:135.738, wght:114.02, lcg:41.400, tcg:15.900, vcg:2.600, fsml:503.728, fsmt:145.294},
        {sounding:2300, pct:0.676, volume:141.908, wght:119.20, lcg:41.400, tcg:15.900, vcg:2.650, fsml:503.728, fsmt:145.294},
        {sounding:2400, pct:0.706, volume:148.077, wght:124.39, lcg:41.400, tcg:15.900, vcg:2.700, fsml:503.728, fsmt:145.294},
        {sounding:2500, pct:0.735, volume:154.247, wght:129.57, lcg:41.400, tcg:15.900, vcg:2.750, fsml:503.728, fsmt:145.294},
        {sounding:2600, pct:0.765, volume:160.417, wght:134.75, lcg:41.400, tcg:15.900, vcg:2.800, fsml:503.728, fsmt:145.294},
        {sounding:2700, pct:0.794, volume:166.587, wght:139.93, lcg:41.400, tcg:15.900, vcg:2.850, fsml:503.728, fsmt:145.294},
        {sounding:2800, pct:0.824, volume:172.757, wght:145.12, lcg:41.400, tcg:15.900, vcg:2.900, fsml:503.728, fsmt:145.294},
        {sounding:2900, pct:0.853, volume:178.927, wght:150.30, lcg:41.400, tcg:15.900, vcg:2.950, fsml:503.728, fsmt:145.294},
        {sounding:3000, pct:0.882, volume:185.097, wght:155.48, lcg:41.400, tcg:15.900, vcg:3.000, fsml:503.728, fsmt:145.294},
        {sounding:3100, pct:0.912, volume:191.267, wght:160.66, lcg:41.400, tcg:15.900, vcg:3.050, fsml:503.728, fsmt:145.294},
        {sounding:3200, pct:0.941, volume:197.437, wght:165.85, lcg:41.400, tcg:15.900, vcg:3.100, fsml:503.728, fsmt:145.294},
        {sounding:3300, pct:0.971, volume:203.606, wght:171.03, lcg:41.400, tcg:15.900, vcg:3.150, fsml:503.728, fsmt:145.294},
        {sounding:3400, pct:1.000, volume:209.779, wght:176.21, lcg:41.400, tcg:15.900, vcg:3.200, fsml:0.000, fsmt:0.000}
    ]
 },
 "FO Day Tk 1": {
    port: [
        {sounding:0, pct:0.000, volume:0.000, wght:0.00,  lcg:46.200, tcg:15.900, vcg:1.550, fsml:0.000, fsmt:0.000},
        {sounding:100, pct:0.029, volume:0.685, wght:0.58, lcg:46.200, tcg:15.900, vcg:1.550, fsml:0.691, fsmt:16.137},
        {sounding:200, pct:0.059, volume:1.371, wght:1.15, lcg:46.200, tcg:15.900, vcg:1.600, fsml:0.689, fsmt:16.137},
        {sounding:300, pct:0.088, volume:2.056, wght:1.73, lcg:46.200, tcg:15.900, vcg:1.650, fsml:0.692, fsmt:16.137},
        {sounding:400, pct:0.118, volume:2.741, wght:2.30, lcg:46.200, tcg:15.900, vcg:1.700, fsml:0.693, fsmt:16.137},
        {sounding:500, pct:0.147, volume:3.427, wght:2.88, lcg:46.200, tcg:15.900, vcg:1.750, fsml:0.690, fsmt:16.137},
        {sounding:600, pct:0.176, volume:4.112, wght:3.45, lcg:46.200, tcg:15.900, vcg:1.800, fsml:0.691, fsmt:16.137},
        {sounding:700, pct:0.206, volume:4.797, wght:4.03, lcg:46.200, tcg:15.900, vcg:1.850, fsml:0.690, fsmt:16.137},
        {sounding:800, pct:0.235, volume:5.483, wght:4.61, lcg:46.200, tcg:15.900, vcg:1.900, fsml:0.691, fsmt:16.137},
        {sounding:900, pct:0.265, volume:6.168, wght:5.18, lcg:46.200, tcg:15.900, vcg:1.950, fsml:0.693, fsmt:16.137},
        {sounding:1000, pct:0.294, volume:6.854, wght:5.76, lcg:46.200, tcg:15.900, vcg:2.000, fsml:0.691, fsmt:16.137},
        {sounding:1100, pct:0.324, volume:7.539, wght:6.33, lcg:46.200, tcg:15.900, vcg:2.050, fsml:0.692, fsmt:16.137},
        {sounding:1200, pct:0.353, volume:8.224, wght:6.91, lcg:46.200, tcg:15.900, vcg:2.100, fsml:0.691, fsmt:16.137},
        {sounding:1300, pct:0.382, volume:8.910, wght:7.48, lcg:46.200, tcg:15.900, vcg:2.150, fsml:0.689, fsmt:16.137},
        {sounding:1400, pct:0.412, volume:9.595, wght:8.06, lcg:46.200, tcg:15.900, vcg:2.200, fsml:0.692, fsmt:16.137},
        {sounding:1500, pct:0.441, volume:10.280, wght:8.64, lcg:46.200, tcg:15.900, vcg:2.250, fsml:0.691, fsmt:16.137},
        {sounding:1600, pct:0.471, volume:10.966, wght:9.21, lcg:46.200, tcg:15.900, vcg:2.300, fsml:0.692, fsmt:16.137},
        {sounding:1700, pct:0.500, volume:11.651, wght:9.79, lcg:46.200, tcg:15.900, vcg:2.350, fsml:0.691, fsmt:16.137},
        {sounding:1800, pct:0.529, volume:12.336, wght:10.36, lcg:46.200, tcg:15.900, vcg:2.400, fsml:0.692, fsmt:16.137},
        {sounding:1900, pct:0.559, volume:13.022, wght:10.94, lcg:46.200, tcg:15.900, vcg:2.450, fsml:0.690, fsmt:16.136},
        {sounding:2000, pct:0.588, volume:13.707, wght:11.51, lcg:46.200, tcg:15.900, vcg:2.500, fsml:0.689, fsmt:16.136},
        {sounding:2100, pct:0.618, volume:14.392, wght:12.09, lcg:46.200, tcg:15.900, vcg:2.550, fsml:0.690, fsmt:16.137},
        {sounding:2200, pct:0.647, volume:15.078, wght:12.67, lcg:46.200, tcg:15.900, vcg:2.600, fsml:0.690, fsmt:16.136},
        {sounding:2300, pct:0.676, volume:15.763, wght:13.24, lcg:46.200, tcg:15.900, vcg:2.650, fsml:0.692, fsmt:16.137},
        {sounding:2400, pct:0.706, volume:16.448, wght:13.82, lcg:46.200, tcg:15.900, vcg:2.700, fsml:0.690, fsmt:16.136},
        {sounding:2500, pct:0.735, volume:17.134, wght:14.39, lcg:46.200, tcg:15.900, vcg:2.750, fsml:0.691, fsmt:16.136},
        {sounding:2600, pct:0.765, volume:17.819, wght:14.97, lcg:46.200, tcg:15.900, vcg:2.800, fsml:0.692, fsmt:16.136},
        {sounding:2700, pct:0.794, volume:18.504, wght:15.54, lcg:46.200, tcg:15.900, vcg:2.850, fsml:0.689, fsmt:16.136},
        {sounding:2800, pct:0.824, volume:19.190, wght:16.12, lcg:46.200, tcg:15.900, vcg:2.900, fsml:0.689, fsmt:16.136},
        {sounding:2900, pct:0.853, volume:19.875, wght:16.70, lcg:46.200, tcg:15.900, vcg:2.950, fsml:0.691, fsmt:16.136},
        {sounding:3000, pct:0.882, volume:20.560, wght:17.27, lcg:46.200, tcg:15.900, vcg:3.000, fsml:0.689, fsmt:16.136},
        {sounding:3100, pct:0.912, volume:21.246, wght:17.85, lcg:46.200, tcg:15.900, vcg:3.050, fsml:0.692, fsmt:16.136},
        {sounding:3200, pct:0.941, volume:21.931, wght:18.42, lcg:46.200, tcg:15.900, vcg:3.100, fsml:0.690, fsmt:16.136},
        {sounding:3300, pct:0.971, volume:22.616, wght:19.00, lcg:46.200, tcg:15.900, vcg:3.150, fsml:0.692, fsmt:16.136},
        {sounding:3400, pct:1.000, volume:23.302, wght:19.57, lcg:46.200, tcg:15.900, vcg:3.200, fsml:0.000, fsmt:0.000}
    ]
 },
 "FO Day Tk 2": {
   
    port: [
        {sounding:0, pct:0.000, volume:0.000, wght:0.00,  lcg:45.000, tcg:15.900, vcg:1.550, fsml:0.000, fsmt:0.000},
        {sounding:100, pct:0.029, volume:0.685, wght:0.58, lcg:45.000, tcg:15.900, vcg:1.550, fsml:0.691, fsmt:16.137},
        {sounding:200, pct:0.059, volume:1.371, wght:1.15, lcg:45.000, tcg:15.900, vcg:1.600, fsml:0.691, fsmt:16.137},
        {sounding:300, pct:0.088, volume:2.056, wght:1.73, lcg:45.000, tcg:15.900, vcg:1.650, fsml:0.692, fsmt:16.137},
        {sounding:400, pct:0.118, volume:2.741, wght:2.30, lcg:45.000, tcg:15.900, vcg:1.700, fsml:0.690, fsmt:16.137},
        {sounding:500, pct:0.147, volume:3.427, wght:2.88, lcg:45.000, tcg:15.900, vcg:1.750, fsml:0.690, fsmt:16.137},
        {sounding:600, pct:0.176, volume:4.112, wght:3.45, lcg:45.000, tcg:15.900, vcg:1.800, fsml:0.691, fsmt:16.137},
        {sounding:700, pct:0.206, volume:4.797, wght:4.03, lcg:45.000, tcg:15.900, vcg:1.850, fsml:0.691, fsmt:16.137},
        {sounding:800, pct:0.235, volume:5.483, wght:4.61, lcg:45.000, tcg:15.900, vcg:1.900, fsml:0.692, fsmt:16.137},
        {sounding:900, pct:0.265, volume:6.168, wght:5.18, lcg:45.000, tcg:15.900, vcg:1.950, fsml:0.692, fsmt:16.137},
        {sounding:1000, pct:0.294, volume:6.854, wght:5.76, lcg:45.000, tcg:15.900, vcg:2.000, fsml:0.691, fsmt:16.137},
        {sounding:1100, pct:0.324, volume:7.539, wght:6.33, lcg:45.000, tcg:15.900, vcg:2.050, fsml:0.691, fsmt:16.137},
        {sounding:1200, pct:0.353, volume:8.224, wght:6.91, lcg:45.000, tcg:15.900, vcg:2.100, fsml:0.691, fsmt:16.137},
        {sounding:1300, pct:0.382, volume:8.910, wght:7.48, lcg:45.000, tcg:15.900, vcg:2.150, fsml:0.690, fsmt:16.137},
        {sounding:1400, pct:0.412, volume:9.595, wght:8.06, lcg:45.000, tcg:15.900, vcg:2.200, fsml:0.692, fsmt:16.137},
        {sounding:1500, pct:0.441, volume:10.280, wght:8.64, lcg:45.000, tcg:15.900, vcg:2.250, fsml:0.691, fsmt:16.137},
        {sounding:1600, pct:0.471, volume:10.966, wght:9.21, lcg:45.000, tcg:15.900, vcg:2.300, fsml:0.691, fsmt:16.137},
        {sounding:1700, pct:0.500, volume:11.651, wght:9.79, lcg:45.000, tcg:15.900, vcg:2.350, fsml:0.691, fsmt:16.137},
        {sounding:1800, pct:0.529, volume:12.336, wght:10.36, lcg:45.000, tcg:15.900, vcg:2.400, fsml:0.692, fsmt:16.137},
        {sounding:1900, pct:0.559, volume:13.022, wght:10.94, lcg:45.000, tcg:15.900, vcg:2.450, fsml:0.692, fsmt:16.136},
        {sounding:2000, pct:0.588, volume:13.707, wght:11.51, lcg:45.000, tcg:15.900, vcg:2.500, fsml:0.690, fsmt:16.136},
        {sounding:2100, pct:0.618, volume:14.392, wght:12.09, lcg:45.000, tcg:15.900, vcg:2.550, fsml:0.690, fsmt:16.137},
        {sounding:2200, pct:0.647, volume:15.078, wght:12.67, lcg:45.000, tcg:15.900, vcg:2.600, fsml:0.690, fsmt:16.136},
        {sounding:2300, pct:0.676, volume:15.763, wght:13.24, lcg:45.000, tcg:15.900, vcg:2.650, fsml:0.692, fsmt:16.137},
        {sounding:2400, pct:0.706, volume:16.448, wght:13.82, lcg:45.000, tcg:15.900, vcg:2.700, fsml:0.692, fsmt:16.136},
        {sounding:2500, pct:0.735, volume:17.134, wght:14.39, lcg:45.000, tcg:15.900, vcg:2.750, fsml:0.692, fsmt:16.136},
        {sounding:2600, pct:0.765, volume:17.819, wght:14.97, lcg:45.000, tcg:15.900, vcg:2.800, fsml:0.689, fsmt:16.136},
        {sounding:2700, pct:0.794, volume:18.504, wght:15.54, lcg:45.000, tcg:15.900, vcg:2.850, fsml:0.690, fsmt:16.136},
        {sounding:2800, pct:0.824, volume:19.190, wght:16.12, lcg:45.000, tcg:15.900, vcg:2.900, fsml:0.689, fsmt:16.136},
        {sounding:2900, pct:0.853, volume:19.875, wght:16.70, lcg:45.000, tcg:15.900, vcg:2.950, fsml:0.689, fsmt:16.136},
        {sounding:3000, pct:0.882, volume:20.560, wght:17.27, lcg:45.000, tcg:15.900, vcg:3.000, fsml:0.690, fsmt:16.136},
        {sounding:3100, pct:0.912, volume:21.246, wght:17.85, lcg:45.000, tcg:15.900, vcg:3.050, fsml:0.692, fsmt:16.136},
        {sounding:3200, pct:0.941, volume:21.931, wght:18.42, lcg:45.000, tcg:15.900, vcg:3.100, fsml:0.690, fsmt:16.136},
        {sounding:3300, pct:0.971, volume:22.616, wght:19.00, lcg:45.000, tcg:15.900, vcg:3.150, fsml:0.690, fsmt:16.136},
        {sounding:3400, pct:1.000, volume:23.302, wght:19.57, lcg:45.000, tcg:15.900, vcg:3.200, fsml:0.000, fsmt:0.000}
    ]
 },
 "Em.Gen FO Tk": {
    port: [
        {sounding:0, pct:0.000, volume:0.000, wght:0.000, lcg:17.400, tcg:16.146, vcg:0.050, fsml:0.000, fsmt:0.000},
        {sounding:100, pct:0.042, volume:0.094, wght:0.08, lcg:4.100, tcg:12.200, vcg:15.150, fsml:0.024, fsmt:0.169},
        {sounding:200, pct:0.083, volume:0.189, wght:0.16, lcg:4.100, tcg:12.200, vcg:15.200, fsml:0.024, fsmt:0.169},
        {sounding:300, pct:0.125, volume:0.283, wght:0.24, lcg:4.100, tcg:12.200, vcg:15.250, fsml:0.024, fsmt:0.169},
        {sounding:400, pct:0.167, volume:0.378, wght:0.32, lcg:4.100, tcg:12.200, vcg:15.300, fsml:0.024, fsmt:0.169},
        {sounding:500, pct:0.208, volume:0.472, wght:0.40, lcg:4.100, tcg:12.200, vcg:15.350, fsml:0.024, fsmt:0.169},
        {sounding:600, pct:0.250, volume:0.567, wght:0.48, lcg:4.100, tcg:12.200, vcg:15.400, fsml:0.024, fsmt:0.169},
        {sounding:700, pct:0.292, volume:0.661, wght:0.56, lcg:4.100, tcg:12.200, vcg:15.450, fsml:0.024, fsmt:0.169},
        {sounding:800, pct:0.333, volume:0.756, wght:0.63, lcg:4.100, tcg:12.200, vcg:15.500, fsml:0.024, fsmt:0.169},
        {sounding:900, pct:0.375, volume:0.850, wght:0.71, lcg:4.100, tcg:12.200, vcg:15.550, fsml:0.024, fsmt:0.169},
        {sounding:1000, pct:0.417, volume:0.945, wght:0.79, lcg:4.100, tcg:12.200, vcg:15.600, fsml:0.024, fsmt:0.169},
        {sounding:1100, pct:0.458, volume:1.039, wght:0.87, lcg:4.100, tcg:12.200, vcg:15.650, fsml:0.024, fsmt:0.169},
        {sounding:1200, pct:0.500, volume:1.134, wght:0.95, lcg:4.100, tcg:12.200, vcg:15.700, fsml:0.024, fsmt:0.169},
        {sounding:1300, pct:0.542, volume:1.228, wght:1.03, lcg:4.100, tcg:12.200, vcg:15.750, fsml:0.024, fsmt:0.169},
        {sounding:1400, pct:0.583, volume:1.323, wght:1.11, lcg:4.100, tcg:12.200, vcg:15.800, fsml:0.024, fsmt:0.169},
        {sounding:1500, pct:0.625, volume:1.417, wght:1.19, lcg:4.100, tcg:12.200, vcg:15.850, fsml:0.024, fsmt:0.169},
        {sounding:1600, pct:0.667, volume:1.512, wght:1.27, lcg:4.100, tcg:12.200, vcg:15.900, fsml:0.024, fsmt:0.169},
        {sounding:1700, pct:0.708, volume:1.606, wght:1.35, lcg:4.100, tcg:12.200, vcg:15.950, fsml:0.024, fsmt:0.169},
        {sounding:1800, pct:0.750, volume:1.701, wght:1.43, lcg:4.100, tcg:12.200, vcg:16.000, fsml:0.024, fsmt:0.169},
        {sounding:1900, pct:0.792, volume:1.795, wght:1.51, lcg:4.100, tcg:12.200, vcg:16.050, fsml:0.024, fsmt:0.169},
        {sounding:2000, pct:0.833, volume:1.889, wght:1.59, lcg:4.100, tcg:12.200, vcg:16.100, fsml:0.024, fsmt:0.169},
        {sounding:2100, pct:0.875, volume:1.984, wght:1.67, lcg:4.100, tcg:12.200, vcg:16.150, fsml:0.024, fsmt:0.169},
        {sounding:2200, pct:0.917, volume:2.078, wght:1.75, lcg:4.100, tcg:12.200, vcg:16.200, fsml:0.024, fsmt:0.169},
        {sounding:2300, pct:0.958, volume:2.173, wght:1.83, lcg:4.100, tcg:12.200, vcg:16.250, fsml:0.024, fsmt:0.169},
        {sounding:2400, pct:1.000, volume:2.267, wght:1.90, lcg:4.100, tcg:12.200, vcg:16.300, fsml:0.000, fsmt:0.000}
    ]
 },
 "LO Tk": {
    port: [
        {sounding:0, pct:0.000, volume:0.000, wght:0.000, lcg:48.000, tcg:19.100, vcg:6.150, fsml:0.000, fsmt:0.000},
        {sounding:100, pct:0.033, volume:0.425, wght:0.36, lcg:48.000, tcg:19.100, vcg:6.150, fsml:1.715, fsmt:0.965},
        {sounding:200, pct:0.067, volume:0.851, wght:0.71, lcg:48.000, tcg:19.100, vcg:6.200, fsml:1.714, fsmt:0.965},
        {sounding:300, pct:0.100, volume:1.276, wght:1.07, lcg:48.000, tcg:19.100, vcg:6.250, fsml:1.714, fsmt:0.965},
        {sounding:400, pct:0.133, volume:1.702, wght:1.43, lcg:48.000, tcg:19.100, vcg:6.300, fsml:1.714, fsmt:0.965},
        {sounding:500, pct:0.167, volume:2.127, wght:1.79, lcg:48.000, tcg:19.100, vcg:6.350, fsml:1.715, fsmt:0.965},
        {sounding:600, pct:0.200, volume:2.553, wght:2.14, lcg:48.000, tcg:19.100, vcg:6.400, fsml:1.715, fsmt:0.965},
        {sounding:700, pct:0.233, volume:2.978, wght:2.50, lcg:48.000, tcg:19.100, vcg:6.450, fsml:1.714, fsmt:0.965},
        {sounding:800, pct:0.267, volume:3.404, wght:2.86, lcg:48.000, tcg:19.100, vcg:6.500, fsml:1.715, fsmt:0.965},
        {sounding:900, pct:0.300, volume:3.829, wght:3.22, lcg:48.000, tcg:19.100, vcg:6.550, fsml:1.716, fsmt:0.965},
        {sounding:1000, pct:0.333, volume:4.255, wght:3.57, lcg:48.000, tcg:19.100, vcg:6.600, fsml:1.715, fsmt:0.965},
        {sounding:1100, pct:0.367, volume:4.680, wght:3.93, lcg:48.000, tcg:19.100, vcg:6.650, fsml:1.716, fsmt:0.965},
        {sounding:1200, pct:0.400, volume:5.106, wght:4.29, lcg:48.000, tcg:19.100, vcg:6.700, fsml:1.714, fsmt:0.965},
        {sounding:1300, pct:0.433, volume:5.531, wght:4.65, lcg:48.000, tcg:19.100, vcg:6.750, fsml:1.715, fsmt:0.965},
        {sounding:1400, pct:0.467, volume:5.956, wght:5.00, lcg:48.000, tcg:19.100, vcg:6.800, fsml:1.714, fsmt:0.965},
        {sounding:1500, pct:0.500, volume:6.382, wght:5.36, lcg:48.000, tcg:19.100, vcg:6.850, fsml:1.714, fsmt:0.965},
        {sounding:1600, pct:0.533, volume:6.807, wght:5.72, lcg:48.000, tcg:19.100, vcg:6.900, fsml:1.715, fsmt:0.965},
        {sounding:1700, pct:0.567, volume:7.233, wght:6.08, lcg:48.000, tcg:19.100, vcg:6.950, fsml:1.715, fsmt:0.965},
        {sounding:1800, pct:0.600, volume:7.658, wght:6.43, lcg:48.000, tcg:19.100, vcg:7.000, fsml:1.714, fsmt:0.965},
        {sounding:1900, pct:0.633, volume:8.084, wght:6.79, lcg:48.000, tcg:19.100, vcg:7.050, fsml:1.714, fsmt:0.965},
        {sounding:2000, pct:0.667, volume:8.509, wght:7.15, lcg:48.000, tcg:19.100, vcg:7.100, fsml:1.715, fsmt:0.965},
        {sounding:2100, pct:0.700, volume:8.935, wght:7.51, lcg:48.000, tcg:19.100, vcg:7.150, fsml:1.715, fsmt:0.965},
        {sounding:2200, pct:0.733, volume:9.360, wght:7.86, lcg:48.000, tcg:19.100, vcg:7.200, fsml:1.713, fsmt:0.965},
        {sounding:2300, pct:0.767, volume:9.786, wght:8.22, lcg:48.000, tcg:19.100, vcg:7.250, fsml:1.715, fsmt:0.965},
        {sounding:2400, pct:0.800, volume:10.211, wght:8.58, lcg:48.000, tcg:19.100, vcg:7.300, fsml:1.714, fsmt:0.965},
        {sounding:2500, pct:0.833, volume:10.637, wght:8.93, lcg:48.000, tcg:19.100, vcg:7.350, fsml:1.715, fsmt:0.965},
        {sounding:2600, pct:0.867, volume:11.062, wght:9.29, lcg:48.000, tcg:19.100, vcg:7.400, fsml:1.715, fsmt:0.965},
        {sounding:2700, pct:0.900, volume:11.488, wght:9.65, lcg:48.000, tcg:19.100, vcg:7.450, fsml:1.715, fsmt:0.965},
        {sounding:2800, pct:0.933, volume:11.913, wght:10.01, lcg:48.000, tcg:19.100, vcg:7.500, fsml:1.714, fsmt:0.965},
        {sounding:2900, pct:0.967, volume:12.338, wght:10.36, lcg:48.000, tcg:19.100, vcg:7.550, fsml:1.714, fsmt:0.965},
        {sounding:3000, pct:1.000, volume:12.765, wght:10.72, lcg:48.000, tcg:19.100, vcg:7.600, fsml:0.000, fsmt:0.000}
    ],   
 },
 "Fresh Water Tk": {
    port: [
        {sounding:0, pct:0.000, volume:0.000, wght:0.000, lcg:17.400, tcg:16.146, vcg:0.050, fsml:0.000, fsmt:0.000},
        {sounding:100, pct:0.028, volume:6.692, wght:6.69, lcg:17.400, tcg:16.146, vcg:0.050, fsml:665.215, fsmt:236.106},
        {sounding:200, pct:0.058, volume:13.630, wght:13.63, lcg:17.400, tcg:16.205, vcg:0.101, fsml:682.208, fsmt:254.657},
        {sounding:300, pct:0.088, volume:20.713, wght:20.71, lcg:17.400, tcg:16.247, vcg:0.152, fsml:694.078, fsmt:268.193},
        {sounding:400, pct:0.118, volume:27.902, wght:27.90, lcg:17.400, tcg:16.281, vcg:0.203, fsml:702.909, fsmt:278.557},
        {sounding:500, pct:0.149, volume:35.171, wght:35.17, lcg:17.400, tcg:16.309, vcg:0.254, fsml:709.760, fsmt:286.774},
        {sounding:600, pct:0.180, volume:42.502, wght:42.50, lcg:17.400, tcg:16.333, vcg:0.305, fsml:715.028, fsmt:293.199},
        {sounding:700, pct:0.211, volume:49.880, wght:49.88, lcg:17.400, tcg:16.353, vcg:0.356, fsml:718.903, fsmt:298.003},
        {sounding:800, pct:0.243, volume:57.291, wght:57.29, lcg:17.400, tcg:16.370, vcg:0.407, fsml:721.602, fsmt:301.362},
        {sounding:900, pct:0.274, volume:64.724, wght:64.72, lcg:17.400, tcg:16.384, vcg:0.458, fsml:723.209, fsmt:303.383},
        {sounding:1000, pct:0.306, volume:72.168, wght:72.17, lcg:17.400, tcg:16.396, vcg:0.509, fsml:723.745, fsmt:304.066},
        {sounding:1100, pct:0.337, volume:79.614, wght:79.61, lcg:17.400, tcg:16.406, vcg:0.559, fsml:723.747, fsmt:304.067},
        {sounding:1200, pct:0.369, volume:87.061, wght:87.06, lcg:17.400, tcg:16.414, vcg:0.610, fsml:723.747, fsmt:304.067},
        {sounding:1300, pct:0.400, volume:94.507, wght:94.51, lcg:17.400, tcg:16.420, vcg:0.660, fsml:723.747, fsmt:304.067},
        {sounding:1400, pct:0.432, volume:101.954, wght:101.95, lcg:17.400, tcg:16.426, vcg:0.711, fsml:723.747, fsmt:304.067},
        {sounding:1500, pct:0.464, volume:109.400, wght:109.40, lcg:17.400, tcg:16.431, vcg:0.761, fsml:723.747, fsmt:304.067},
        {sounding:1600, pct:0.495, volume:116.846, wght:116.85, lcg:17.400, tcg:16.436, vcg:0.811, fsml:723.747, fsmt:304.067},
        {sounding:1700, pct:0.527, volume:124.293, wght:124.29, lcg:17.400, tcg:16.440, vcg:0.862, fsml:723.747, fsmt:304.067},
        {sounding:1800, pct:0.558, volume:131.739, wght:131.74, lcg:17.400, tcg:16.443, vcg:0.912, fsml:723.747, fsmt:304.067},
        {sounding:1900, pct:0.590, volume:139.186, wght:139.19, lcg:17.400, tcg:16.446, vcg:0.962, fsml:723.747, fsmt:304.067},
        {sounding:2000, pct:0.621, volume:146.632, wght:146.63, lcg:17.400, tcg:16.449, vcg:1.012, fsml:723.747, fsmt:304.067},
        {sounding:2100, pct:0.653, volume:154.078, wght:154.08, lcg:17.400, tcg:16.451, vcg:1.062, fsml:723.747, fsmt:304.067},
        {sounding:2200, pct:0.684, volume:161.525, wght:161.52, lcg:17.400, tcg:16.453, vcg:1.112, fsml:723.747, fsmt:304.067},
        {sounding:2300, pct:0.716, volume:168.971, wght:168.97, lcg:17.400, tcg:16.456, vcg:1.163, fsml:723.747, fsmt:304.067},
        {sounding:2400, pct:0.748, volume:176.418, wght:176.42, lcg:17.400, tcg:16.457, vcg:1.213, fsml:723.747, fsmt:304.067},
        {sounding:2500, pct:0.779, volume:183.864, wght:183.86, lcg:17.400, tcg:16.459, vcg:1.263, fsml:723.747, fsmt:304.067},
        {sounding:2600, pct:0.811, volume:191.310, wght:191.31, lcg:17.400, tcg:16.461, vcg:1.313, fsml:723.747, fsmt:304.067},
        {sounding:2700, pct:0.842, volume:198.757, wght:198.76, lcg:17.400, tcg:16.462, vcg:1.363, fsml:723.747, fsmt:304.067},
        {sounding:2800, pct:0.874, volume:206.203, wght:206.20, lcg:17.400, tcg:16.464, vcg:1.413, fsml:723.747, fsmt:304.067},
        {sounding:2900, pct:0.905, volume:213.650, wght:213.65, lcg:17.400, tcg:16.465, vcg:1.463, fsml:723.747, fsmt:304.067},
        {sounding:3000, pct:0.937, volume:221.096, wght:221.10, lcg:17.400, tcg:16.466, vcg:1.513, fsml:723.747, fsmt:304.067},
        {sounding:3100, pct:0.968, volume:228.542, wght:228.54, lcg:17.400, tcg:16.467, vcg:1.563, fsml:723.747, fsmt:304.067},
        {sounding:3200, pct:1.000, volume:235.996, wght:236.00, lcg:17.400, tcg:16.468, vcg:1.613, fsml:0.000, fsmt:0.000},
    ],
    starboard: [
        {sounding:0, pct:0.000, volume:0.000, wght:0.000, lcg:17.400, tcg:16.146, vcg:0.050, fsml:0.000, fsmt:0.000},
        {sounding:100, pct:0.028, volume:6.692, wght:6.69, lcg:17.400, tcg:16.146, vcg:0.050, fsml:665.214, fsmt:236.100},
        {sounding:200, pct:0.058, volume:13.630, wght:13.63, lcg:17.400, tcg:16.205, vcg:0.101, fsml:682.209, fsmt:254.656},
        {sounding:300, pct:0.088, volume:20.713, wght:20.71, lcg:17.400, tcg:16.247, vcg:0.152, fsml:694.080, fsmt:268.184},
        {sounding:400, pct:0.118, volume:27.902, wght:27.90, lcg:17.400, tcg:16.281, vcg:0.203, fsml:702.911, fsmt:278.551},
        {sounding:500, pct:0.149, volume:35.171, wght:35.17, lcg:17.400, tcg:16.309, vcg:0.254, fsml:709.764, fsmt:286.781},
        {sounding:600, pct:0.180, volume:42.502, wght:42.50, lcg:17.400, tcg:16.333, vcg:0.305, fsml:715.024, fsmt:293.197},
        {sounding:700, pct:0.211, volume:49.880, wght:49.88, lcg:17.400, tcg:16.353, vcg:0.356, fsml:718.910, fsmt:298.001},
        {sounding:800, pct:0.243, volume:57.291, wght:57.29, lcg:17.400, tcg:16.370, vcg:0.407, fsml:721.601, fsmt:301.363},
        {sounding:900, pct:0.274, volume:64.724, wght:64.72, lcg:17.400, tcg:16.384, vcg:0.458, fsml:723.205, fsmt:303.379},
        {sounding:1000, pct:0.306, volume:72.168, wght:72.17, lcg:17.400, tcg:16.396, vcg:0.509, fsml:723.749, fsmt:304.065},
        {sounding:1100, pct:0.337, volume:79.614, wght:79.61, lcg:17.400, tcg:16.406, vcg:0.559, fsml:723.747, fsmt:304.066},
        {sounding:1200, pct:0.369, volume:87.061, wght:87.06, lcg:17.400, tcg:16.414, vcg:0.610, fsml:723.747, fsmt:304.066},
        {sounding:1300, pct:0.400, volume:94.507, wght:94.51, lcg:17.400, tcg:16.420, vcg:0.660, fsml:723.747, fsmt:304.066},
        {sounding:1400, pct:0.432, volume:101.954, wght:101.95, lcg:17.400, tcg:16.426, vcg:0.711, fsml:723.747, fsmt:304.066},
        {sounding:1500, pct:0.464, volume:109.400, wght:109.40, lcg:17.400, tcg:16.431, vcg:0.761, fsml:723.747, fsmt:304.066},
        {sounding:1600, pct:0.495, volume:116.846, wght:116.85, lcg:17.400, tcg:16.436, vcg:0.811, fsml:723.747, fsmt:304.066},
        {sounding:1700, pct:0.527, volume:124.293, wght:124.29, lcg:17.400, tcg:16.440, vcg:0.862, fsml:723.747, fsmt:304.066},
        {sounding:1800, pct:0.558, volume:131.739, wght:131.74, lcg:17.400, tcg:16.443, vcg:0.912, fsml:723.747, fsmt:304.066},
        {sounding:1900, pct:0.590, volume:139.186, wght:139.19, lcg:17.400, tcg:16.446, vcg:0.962, fsml:723.747, fsmt:304.066},
        {sounding:2000, pct:0.621, volume:146.632, wght:146.63, lcg:17.400, tcg:16.449, vcg:1.012, fsml:723.747, fsmt:304.066},
        {sounding:2100, pct:0.653, volume:154.078, wght:154.08, lcg:17.400, tcg:16.451, vcg:1.062, fsml:723.747, fsmt:304.066},
        {sounding:2200, pct:0.684, volume:161.525, wght:161.52, lcg:17.400, tcg:16.453, vcg:1.112, fsml:723.747, fsmt:304.066},
        {sounding:2300, pct:0.716, volume:168.971, wght:168.97, lcg:17.400, tcg:16.456, vcg:1.163, fsml:723.747, fsmt:304.066},
        {sounding:2400, pct:0.748, volume:176.418, wght:176.42, lcg:17.400, tcg:16.457, vcg:1.213, fsml:723.747, fsmt:304.066},
        {sounding:2500, pct:0.779, volume:183.864, wght:183.86, lcg:17.400, tcg:16.459, vcg:1.263, fsml:723.747, fsmt:304.066},
        {sounding:2600, pct:0.811, volume:191.310, wght:191.31, lcg:17.400, tcg:16.461, vcg:1.313, fsml:723.747, fsmt:304.066},
        {sounding:2700, pct:0.842, volume:198.757, wght:198.76, lcg:17.400, tcg:16.462, vcg:1.363, fsml:723.747, fsmt:304.066},
        {sounding:2800, pct:0.874, volume:206.203, wght:206.20, lcg:17.400, tcg:16.464, vcg:1.413, fsml:723.747, fsmt:304.066},
        {sounding:2900, pct:0.905, volume:213.650, wght:213.65, lcg:17.400, tcg:16.465, vcg:1.463, fsml:723.747, fsmt:304.066},
        {sounding:3000, pct:0.937, volume:221.096, wght:221.10, lcg:17.400, tcg:16.466, vcg:1.513, fsml:723.747, fsmt:304.066},
        {sounding:3100, pct:0.968, volume:228.543, wght:228.54, lcg:17.400, tcg:16.467, vcg:1.563, fsml:723.747, fsmt:304.066},
        {sounding:3200, pct:1.000, volume:235.996, wght:236.00, lcg:17.400, tcg:16.468, vcg:1.613, fsml:0.000, fsmt:0.000}
    ]
 },
 "Black Water Tk": {
    port: [
        {sounding:0, pct:0.000, volume:0.000, wght:0.000, lcg:38.400, tcg:10.100, vcg:0.050, fsml:0.000, fsmt:0.000},
        {sounding:100, pct:0.031, volume:4.113, wght:4.22, lcg:8.400, tcg:10.100, vcg:0.050, fsml:182.117, fsmt:118.191},
        {sounding:200, pct:0.062, volume:8.226, wght:8.43, lcg:8.400, tcg:10.100, vcg:0.100, fsml:182.117, fsmt:118.191},
        {sounding:300, pct:0.094, volume:12.340, wght:12.65, lcg:8.400, tcg:10.100, vcg:0.150, fsml:182.117, fsmt:118.191},
        {sounding:400, pct:0.125, volume:16.453, wght:16.86, lcg:8.400, tcg:10.100, vcg:0.200, fsml:182.117, fsmt:118.191},
        {sounding:500, pct:0.156, volume:20.566, wght:21.08, lcg:8.400, tcg:10.100, vcg:0.250, fsml:182.117, fsmt:118.191},
        {sounding:600, pct:0.187, volume:24.679, wght:25.30, lcg:8.400, tcg:10.100, vcg:0.300, fsml:182.117, fsmt:118.191},
        {sounding:700, pct:0.219, volume:28.792, wght:29.51, lcg:8.400, tcg:10.100, vcg:0.350, fsml:182.117, fsmt:118.191},
        {sounding:800, pct:0.250, volume:32.906, wght:33.73, lcg:8.400, tcg:10.100, vcg:0.400, fsml:182.117, fsmt:118.191},
        {sounding:900, pct:0.281, volume:37.019, wght:37.94, lcg:8.400, tcg:10.100, vcg:0.450, fsml:182.117, fsmt:118.191},
        {sounding:1000, pct:0.312, volume:41.132, wght:42.16, lcg:8.400, tcg:10.100, vcg:0.500, fsml:182.117, fsmt:118.191},
        {sounding:1100, pct:0.344, volume:45.245, wght:46.38, lcg:8.400, tcg:10.100, vcg:0.550, fsml:182.117, fsmt:118.191},
        {sounding:1200, pct:0.375, volume:49.358, wght:50.59, lcg:8.400, tcg:10.100, vcg:0.600, fsml:182.117, fsmt:118.191},
        {sounding:1300, pct:0.406, volume:53.472, wght:54.81, lcg:8.400, tcg:10.100, vcg:0.650, fsml:182.117, fsmt:118.191},
        {sounding:1400, pct:0.437, volume:57.585, wght:59.02, lcg:8.400, tcg:10.100, vcg:0.700, fsml:182.117, fsmt:118.191},
        {sounding:1500, pct:0.469, volume:61.698, wght:63.24, lcg:8.400, tcg:10.100, vcg:0.750, fsml:182.117, fsmt:118.191},
        {sounding:1600, pct:0.500, volume:65.811, wght:67.46, lcg:8.400, tcg:10.100, vcg:0.800, fsml:182.117, fsmt:118.191},
        {sounding:1700, pct:0.531, volume:69.925, wght:71.67, lcg:8.400, tcg:10.100, vcg:0.850, fsml:182.117, fsmt:118.191},
        {sounding:1800, pct:0.562, volume:74.038, wght:75.89, lcg:8.400, tcg:10.100, vcg:0.900, fsml:182.117, fsmt:118.191},
        {sounding:1900, pct:0.594, volume:78.151, wght:80.10, lcg:8.400, tcg:10.100, vcg:0.950, fsml:182.117, fsmt:118.191},
        {sounding:2000, pct:0.625, volume:82.264, wght:84.32, lcg:8.400, tcg:10.100, vcg:1.000, fsml:182.117, fsmt:118.191},
        {sounding:2100, pct:0.656, volume:86.377, wght:88.54, lcg:8.400, tcg:10.100, vcg:1.050, fsml:182.117, fsmt:118.191},
        {sounding:2200, pct:0.687, volume:90.491, wght:92.75, lcg:8.400, tcg:10.100, vcg:1.100, fsml:182.117, fsmt:118.191},
        {sounding:2300, pct:0.719, volume:94.604, wght:96.97, lcg:8.400, tcg:10.100, vcg:1.150, fsml:182.117, fsmt:118.191},
        {sounding:2400, pct:0.750, volume:98.717, wght:101.18, lcg:8.400, tcg:10.100, vcg:1.200, fsml:182.117, fsmt:118.191},
        {sounding:2500, pct:0.781, volume:102.830, wght:105.40, lcg:8.400, tcg:10.100, vcg:1.250, fsml:182.117, fsmt:118.191},
        {sounding:2600, pct:0.812, volume:106.943, wght:109.62, lcg:8.400, tcg:10.100, vcg:1.300, fsml:182.117, fsmt:118.191},
        {sounding:2700, pct:0.844, volume:111.057, wght:113.83, lcg:8.400, tcg:10.100, vcg:1.350, fsml:182.117, fsmt:118.191},
        {sounding:2800, pct:0.875, volume:115.170, wght:118.05, lcg:8.400, tcg:10.100, vcg:1.400, fsml:182.117, fsmt:118.191},
        {sounding:2900, pct:0.906, volume:119.283, wght:122.27, lcg:8.400, tcg:10.100, vcg:1.450, fsml:182.117, fsmt:118.191},
        {sounding:3000, pct:0.937, volume:123.396, wght:126.48, lcg:8.400, tcg:10.100, vcg:1.500, fsml:182.117, fsmt:118.191},
        {sounding:3100, pct:0.969, volume:127.509, wght:130.70, lcg:8.400, tcg:10.100, vcg:1.550, fsml:182.117, fsmt:118.191},
        {sounding:3200, pct:1.000, volume:131.626, wght:134.92, lcg:8.400, tcg:10.100, vcg:1.600, fsml:0.000, fsmt:0.000} 
    ],   
 },
 "Gray Water Tk": {
    port: [
        {sounding:0, pct:0.000, volume:0.000, wght:0.000, lcg:38.400, tcg:11.000, vcg:0.050, fsml:0.000, fsmt:0.000},
        {sounding:100, pct:0.031, volume:4.113, wght:4.22, lcg:8.400, tcg:10.100, vcg:0.050, fsml:182.117, fsmt:118.191},
        {sounding:200, pct:0.062, volume:8.226, wght:8.43, lcg:8.400, tcg:10.100, vcg:0.100, fsml:182.117, fsmt:118.191},
        {sounding:300, pct:0.094, volume:12.340, wght:12.65, lcg:8.400, tcg:10.100, vcg:0.150, fsml:182.117, fsmt:118.191},
        {sounding:400, pct:0.125, volume:16.453, wght:16.86, lcg:8.400, tcg:10.100, vcg:0.200, fsml:182.117, fsmt:118.191},
        {sounding:500, pct:0.156, volume:20.566, wght:21.08, lcg:8.400, tcg:10.100, vcg:0.250, fsml:182.117, fsmt:118.191},
        {sounding:600, pct:0.187, volume:24.679, wght:25.30, lcg:8.400, tcg:10.100, vcg:0.300, fsml:182.117, fsmt:118.191},
        {sounding:700, pct:0.219, volume:28.792, wght:29.51, lcg:8.400, tcg:10.100, vcg:0.350, fsml:182.117, fsmt:118.191},
        {sounding:800, pct:0.250, volume:32.906, wght:33.73, lcg:8.400, tcg:10.100, vcg:0.400, fsml:182.117, fsmt:118.191},
        {sounding:900, pct:0.281, volume:37.019, wght:37.94, lcg:8.400, tcg:10.100, vcg:0.450, fsml:182.117, fsmt:118.191},
        {sounding:1000, pct:0.312, volume:41.132, wght:42.16, lcg:8.400, tcg:10.100, vcg:0.500, fsml:182.117, fsmt:118.191},
        {sounding:1100, pct:0.344, volume:45.245, wght:46.38, lcg:8.400, tcg:10.100, vcg:0.550, fsml:182.117, fsmt:118.191},
        {sounding:1200, pct:0.375, volume:49.358, wght:50.59, lcg:8.400, tcg:10.100, vcg:0.600, fsml:182.117, fsmt:118.191},
        {sounding:1300, pct:0.406, volume:53.472, wght:54.81, lcg:8.400, tcg:10.100, vcg:0.650, fsml:182.117, fsmt:118.191},
        {sounding:1400, pct:0.437, volume:57.585, wght:59.02, lcg:8.400, tcg:10.100, vcg:0.700, fsml:182.117, fsmt:118.191},
        {sounding:1500, pct:0.469, volume:61.698, wght:63.24, lcg:8.400, tcg:10.100, vcg:0.750, fsml:182.117, fsmt:118.191},
        {sounding:1600, pct:0.500, volume:65.811, wght:67.46, lcg:8.400, tcg:10.100, vcg:0.800, fsml:182.117, fsmt:118.191},
        {sounding:1700, pct:0.531, volume:69.925, wght:71.67, lcg:8.400, tcg:10.100, vcg:0.850, fsml:182.117, fsmt:118.191},
        {sounding:1800, pct:0.562, volume:74.038, wght:75.89, lcg:8.400, tcg:10.100, vcg:0.900, fsml:182.117, fsmt:118.191},
        {sounding:1900, pct:0.594, volume:78.151, wght:80.10, lcg:8.400, tcg:10.100, vcg:0.950, fsml:182.117, fsmt:118.191},
        {sounding:2000, pct:0.625, volume:82.264, wght:84.32, lcg:8.400, tcg:10.100, vcg:1.000, fsml:182.117, fsmt:118.191},
        {sounding:2100, pct:0.656, volume:86.377, wght:88.54, lcg:8.400, tcg:10.100, vcg:1.050, fsml:182.117, fsmt:118.191},
        {sounding:2200, pct:0.687, volume:90.491, wght:92.75, lcg:8.400, tcg:10.100, vcg:1.100, fsml:182.117, fsmt:118.191},
        {sounding:2300, pct:0.719, volume:94.604, wght:96.97, lcg:8.400, tcg:10.100, vcg:1.150, fsml:182.117, fsmt:118.191},
        {sounding:2400, pct:0.750, volume:98.717, wght:101.18, lcg:8.400, tcg:10.100, vcg:1.200, fsml:182.117, fsmt:118.191},
        {sounding:2500, pct:0.781, volume:102.830, wght:105.40, lcg:8.400, tcg:10.100, vcg:1.250, fsml:182.117, fsmt:118.191},
        {sounding:2600, pct:0.812, volume:106.943, wght:109.62, lcg:8.400, tcg:10.100, vcg:1.300, fsml:182.117, fsmt:118.191},
        {sounding:2700, pct:0.844, volume:111.057, wght:113.83, lcg:8.400, tcg:10.100, vcg:1.350, fsml:182.117, fsmt:118.191},
        {sounding:2800, pct:0.875, volume:115.170, wght:118.05, lcg:8.400, tcg:10.100, vcg:1.400, fsml:182.117, fsmt:118.191},
        {sounding:2900, pct:0.906, volume:119.283, wght:122.27, lcg:8.400, tcg:10.100, vcg:1.450, fsml:182.117, fsmt:118.191},
        {sounding:3000, pct:0.937, volume:123.396, wght:126.48, lcg:8.400, tcg:10.100, vcg:1.500, fsml:182.117, fsmt:118.191},
        {sounding:3100, pct:0.969, volume:127.509, wght:130.70, lcg:8.400, tcg:10.100, vcg:1.550, fsml:182.117, fsmt:118.191},
        {sounding:3200, pct:1.000, volume:131.626, wght:134.92, lcg:8.400, tcg:10.100, vcg:1.600, fsml:0.000, fsmt:0.000}

    ],   
 },
 "Dirty Oil Tk": {
    port: [
        {sounding:0, pct:0.000, volume:0.000, wght:0.000, lcg:42.600, tcg:11.000, vcg:0.750, fsml:0.000, fsmt:0.000},
        {sounding:100, pct:0.125, volume:1.418, wght:1.19, lcg:42.600, tcg:11.000, vcg:0.750, fsml:12.861, fsmt:15.883},
        {sounding:200, pct:0.250, volume:2.836, wght:2.38, lcg:42.600, tcg:11.000, vcg:0.800, fsml:12.860, fsmt:15.883},
        {sounding:300, pct:0.375, volume:4.255, wght:3.57, lcg:42.600, tcg:11.000, vcg:0.850, fsml:12.862, fsmt:15.883},
        {sounding:400, pct:0.500, volume:5.673, wght:4.77, lcg:42.600, tcg:11.000, vcg:0.900, fsml:12.862, fsmt:15.883},
        {sounding:500, pct:0.625, volume:7.091, wght:5.96, lcg:42.600, tcg:11.000, vcg:0.950, fsml:12.864, fsmt:15.882},
        {sounding:600, pct:0.750, volume:8.509, wght:7.15, lcg:42.600, tcg:11.000, vcg:1.000, fsml:12.862, fsmt:15.882},
        {sounding:700, pct:0.875, volume:9.928, wght:8.34, lcg:42.600, tcg:11.000, vcg:1.050, fsml:12.858, fsmt:15.882},
        {sounding:800, pct:1.000, volume:11.343, wght:9.53, lcg:42.600, tcg:11.000, vcg:1.100, fsml:0.000, fsmt:0.000}    
        ],   
 },
 "Bilge Water Tk": {
    port: [
        {sounding:0, pct:0.000, volume:0.000, wght:0.000, lcg:38.400, tcg:11.000, vcg:0.050, fsml:0.000, fsmt:0.000},
        {sounding:100, pct:0.067, volume:1.891, wght:1.94, lcg:38.400, tcg:11.000, vcg:0.050, fsml:37.207, fsmt:25.843},
        {sounding:200, pct:0.133, volume:3.782, wght:3.88, lcg:38.400, tcg:11.000, vcg:0.100, fsml:37.207, fsmt:25.843},
        {sounding:300, pct:0.200, volume:5.673, wght:5.81, lcg:38.400, tcg:11.000, vcg:0.150, fsml:37.207, fsmt:25.843},
        {sounding:400, pct:0.267, volume:7.564, wght:7.75, lcg:38.400, tcg:11.000, vcg:0.200, fsml:37.207, fsmt:25.843},
        {sounding:500, pct:0.333, volume:9.455, wght:9.69, lcg:38.400, tcg:11.000, vcg:0.250, fsml:37.207, fsmt:25.843},
        {sounding:600, pct:0.400, volume:11.346, wght:11.63, lcg:38.400, tcg:11.000, vcg:0.300, fsml:37.207, fsmt:25.843},
        {sounding:700, pct:0.467, volume:13.237, wght:13.57, lcg:38.400, tcg:11.000, vcg:0.350, fsml:37.207, fsmt:25.843},
        {sounding:800, pct:0.533, volume:15.128, wght:15.51, lcg:38.400, tcg:11.000, vcg:0.400, fsml:37.207, fsmt:25.843},
        {sounding:900, pct:0.600, volume:17.019, wght:17.44, lcg:38.400, tcg:11.000, vcg:0.450, fsml:37.207, fsmt:25.843},
        {sounding:1000, pct:0.667, volume:18.910, wght:19.38, lcg:38.400, tcg:11.000, vcg:0.500, fsml:37.207, fsmt:25.843},
        {sounding:1100, pct:0.733, volume:20.801, wght:21.32, lcg:38.400, tcg:11.000, vcg:0.550, fsml:37.207, fsmt:25.843},
        {sounding:1200, pct:0.800, volume:22.692, wght:23.26, lcg:38.400, tcg:11.000, vcg:0.600, fsml:37.207, fsmt:25.843},
        {sounding:1300, pct:0.867, volume:24.583, wght:25.20, lcg:38.400, tcg:11.000, vcg:0.650, fsml:37.207, fsmt:25.843},
        {sounding:1400, pct:0.933, volume:26.474, wght:27.14, lcg:38.400, tcg:11.000, vcg:0.700, fsml:37.207, fsmt:25.843},
        {sounding:1500, pct:1.000, volume:28.364, wght:29.07, lcg:38.400, tcg:11.000, vcg:0.750, fsml:0.000, fsmt:0.000}
    ],   
 },
 "Sea Water Reservoir": {
    port: [
        {sounding:0, pct:0.000, volume:0.000, wght:0.000, lcg:38.400, tcg:11.000, vcg:0.050, fsml:0.000, fsmt:0.000},
        {sounding:100, pct:0.000, volume:0.109, wght:0.11, lcg:56.488, tcg:0.000, vcg:1.097, fsml:0.005, fsmt:3.393},
        {sounding:200, pct:0.001, volume:0.437, wght:0.45, lcg:56.576, tcg:0.000, vcg:1.153, fsml:0.036, fsmt:6.786},
        {sounding:300, pct:0.003, volume:0.984, wght:1.01, lcg:56.664, tcg:0.000, vcg:1.209, fsml:0.117, fsmt:10.180},
        {sounding:400, pct:0.006, volume:1.750, wght:1.79, lcg:56.753, tcg:0.000, vcg:1.266, fsml:0.278, fsmt:13.573},
        {sounding:500, pct:0.009, volume:2.734, wght:2.80, lcg:56.841, tcg:0.000, vcg:1.322, fsml:0.542, fsmt:16.966},
        {sounding:600, pct:0.013, volume:3.936, wght:4.03, lcg:56.929, tcg:0.000, vcg:1.378, fsml:0.941, fsmt:20.359},
        {sounding:700, pct:0.018, volume:5.358, wght:5.49, lcg:57.017, tcg:0.000, vcg:1.480, fsml:4.472, fsmt:92.269},
        {sounding:800, pct:0.024, volume:6.998, wght:7.17, lcg:57.105, tcg:0.000, vcg:1.562, fsml:6.678, fsmt:105.451},
        {sounding:900, pct:0.030, volume:8.857, wght:9.08, lcg:57.193, tcg:0.000, vcg:1.633, fsml:9.513, fsmt:118.632},
        {sounding:1000, pct:0.037, volume:10.934, wght:11.21, lcg:57.281, tcg:0.000, vcg:1.699, fsml:13.048, fsmt:131.813},
        {sounding:1100, pct:0.045, volume:13.231, wght:13.56, lcg:57.369, tcg:0.000, vcg:1.762, fsml:17.357, fsmt:144.994},
        {sounding:1200, pct:0.053, volume:15.746, wght:16.14, lcg:57.457, tcg:0.000, vcg:1.825, fsml:22.546, fsmt:158.176},
        {sounding:1300, pct:0.062, volume:18.488, wght:18.95, lcg:57.547, tcg:0.000, vcg:1.899, fsml:29.271, fsmt:172.574},
        {sounding:1400, pct:0.072, volume:21.472, wght:22.01, lcg:57.638, tcg:0.000, vcg:1.970, fsml:37.309, fsmt:187.117},
        {sounding:1500, pct:0.083, volume:24.697, wght:25.31, lcg:57.731, tcg:0.000, vcg:2.039, fsml:46.714, fsmt:201.661},
        {sounding:1600, pct:0.095, volume:28.164, wght:28.87, lcg:57.825, tcg:0.000, vcg:2.106, fsml:57.564, fsmt:216.204},
        {sounding:1700, pct:0.108, volume:31.871, wght:32.67, lcg:57.920, tcg:0.000, vcg:2.172, fsml:70.004, fsmt:230.747},
        {sounding:1800, pct:0.126, volume:37.416, wght:38.35, lcg:58.255, tcg:0.000, vcg:2.265, fsml:625.223, fsmt:478.760},
        {sounding:1900, pct:0.153, volume:45.359, wght:46.49, lcg:58.771, tcg:0.000, vcg:2.376, fsml:625.223, fsmt:478.760},
        {sounding:2000, pct:0.180, volume:53.302, wght:54.64, lcg:59.133, tcg:0.000, vcg:2.469, fsml:625.223, fsmt:478.760},
        {sounding:2100, pct:0.207, volume:61.246, wght:62.78, lcg:59.401, tcg:0.000, vcg:2.550, fsml:625.223, fsmt:478.760},
        {sounding:2200, pct:0.234, volume:69.189, wght:70.92, lcg:59.607, tcg:0.000, vcg:2.623, fsml:625.223, fsmt:478.760},
        {sounding:2300, pct:0.260, volume:77.132, wght:79.06, lcg:59.771, tcg:0.000, vcg:2.692, fsml:625.223, fsmt:478.760},
        {sounding:2400, pct:0.287, volume:85.075, wght:87.20, lcg:59.905, tcg:0.000, vcg:2.757, fsml:625.223, fsmt:478.760},
        {sounding:2500, pct:0.314, volume:93.018, wght:95.34, lcg:60.015, tcg:0.000, vcg:2.820, fsml:625.223, fsmt:478.760},
        {sounding:2600, pct:0.341, volume:100.961, wght:103.48, lcg:60.109, tcg:0.000, vcg:2.881, fsml:625.223, fsmt:478.760},
        {sounding:2700, pct:0.368, volume:108.904, wght:111.63, lcg:60.188, tcg:0.000, vcg:2.940, fsml:625.223, fsmt:478.760},
        {sounding:2800, pct:0.394, volume:116.847, wght:119.77, lcg:60.257, tcg:0.000, vcg:2.998, fsml:625.223, fsmt:478.760},
        {sounding:2900, pct:0.421, volume:124.790, wght:127.91, lcg:60.317, tcg:0.000, vcg:3.054, fsml:625.223, fsmt:478.760},
        {sounding:3000, pct:0.448, volume:132.733, wght:136.05, lcg:60.370, tcg:0.000, vcg:3.110, fsml:625.223, fsmt:478.760},
        {sounding:3100, pct:0.475, volume:140.676, wght:144.19, lcg:60.417, tcg:0.000, vcg:3.166, fsml:625.223, fsmt:478.760},
        {sounding:3200, pct:0.502, volume:148.619, wght:152.33, lcg:60.459, tcg:0.000, vcg:3.221, fsml:625.223, fsmt:478.760},
        {sounding:3300, pct:0.528, volume:156.562, wght:160.48, lcg:60.496, tcg:0.000, vcg:3.275, fsml:625.223, fsmt:478.760},
        {sounding:3400, pct:0.555, volume:164.505, wght:168.62, lcg:60.530, tcg:0.000, vcg:3.329, fsml:625.223, fsmt:478.760},
        {sounding:3500, pct:0.582, volume:172.448, wght:176.76, lcg:60.561, tcg:0.000, vcg:3.382, fsml:625.223, fsmt:478.760},
        {sounding:3600, pct:0.609, volume:180.391, wght:184.90, lcg:60.589, tcg:0.000, vcg:3.435, fsml:625.223, fsmt:478.760},
        {sounding:3700, pct:0.636, volume:188.334, wght:193.04, lcg:60.615, tcg:0.000, vcg:3.488, fsml:625.223, fsmt:478.760},
        {sounding:3800, pct:0.662, volume:196.277, wght:201.18, lcg:60.639, tcg:0.000, vcg:3.541, fsml:625.223, fsmt:478.760},
        {sounding:3900, pct:0.689, volume:204.220, wght:209.33, lcg:60.660, tcg:0.000, vcg:3.594, fsml:625.223, fsmt:478.760},
        {sounding:4000, pct:0.716, volume:212.163, wght:217.47, lcg:60.681, tcg:0.000, vcg:3.646, fsml:625.223, fsmt:478.760},
        {sounding:4100, pct:0.743, volume:220.106, wght:225.61, lcg:60.699, tcg:0.000, vcg:3.698, fsml:625.223, fsmt:478.760},
        {sounding:4200, pct:0.770, volume:228.049, wght:233.75, lcg:60.717, tcg:0.000, vcg:3.750, fsml:625.223, fsmt:478.760},
        {sounding:4300, pct:0.796, volume:235.992, wght:241.89, lcg:60.733, tcg:0.000, vcg:3.802, fsml:625.223, fsmt:478.760},
        {sounding:4400, pct:0.823, volume:243.935, wght:250.03, lcg:60.748, tcg:0.000, vcg:3.854, fsml:625.223, fsmt:478.760},
        {sounding:4500, pct:0.850, volume:251.878, wght:258.18, lcg:60.763, tcg:0.000, vcg:3.905, fsml:625.223, fsmt:478.760},
        {sounding:4600, pct:0.877, volume:259.822, wght:266.32, lcg:60.776, tcg:0.000, vcg:3.957, fsml:625.223, fsmt:478.760},
        {sounding:4700, pct:0.904, volume:267.765, wght:274.46, lcg:60.789, tcg:0.000, vcg:4.008, fsml:625.223, fsmt:478.760},
        {sounding:4800, pct:0.930, volume:275.708, wght:282.60, lcg:60.800, tcg:0.000, vcg:4.060, fsml:625.223, fsmt:478.760},
        {sounding:4900, pct:0.957, volume:283.651, wght:290.74, lcg:60.812, tcg:0.000, vcg:4.111, fsml:625.223, fsmt:478.760},
        {sounding:5000, pct:0.984, volume:291.594, wght:298.88, lcg:60.822, tcg:0.000, vcg:4.162, fsml:625.223, fsmt:478.760},
        {sounding:5059, pct:1.000, volume:296.310, wght:303.72, lcg:60.828, tcg:0.000, vcg:4.192, fsml:0.000, fsmt:0.000},
    ],   
 },
}