import React from "react";
import { Container, Table } from "react-bootstrap";
import styles from "./css/report.module.css";

const SoundingsReport = () => {
  // Retrieve data from local storage
  const soundings = JSON.parse(localStorage.getItem("tankSoundings")) || {};
  const volumes = JSON.parse(localStorage.getItem("tankVolumes")) || {};
  const bargeName = localStorage.getItem("bargeName") || "N/A";
  const reportDate = localStorage.getItem("reportDate") || "N/A";

  return (
    <Container className="mt-4">      
      <Table bordered id="sounding-table" className={`${styles.sounding_table} ${styles.sounding_report}`}>
        <thead>
          <tr className={`${styles.bg_style}`}>
            <th className={`align-middle`} colSpan="2">
             {bargeName}
            </th>
            <th>Date: {reportDate}</th>          
          </tr>
        </thead>

        <thead>
          <tr >           
            <th colSpan="3"><h5>Tanks Sounding Report</h5></th>          
          </tr>
        </thead>

        <thead>
          <tr>
            <th className={`align-middle`} colSpan="1">
             Tank Name
            </th>
            <th>Port Side <br />(mm & m³)</th>
            <th>Starboard Side <br />(mm & m³)</th>
          </tr>
        </thead>
        <tbody>
          {/* BALLAST TANKS */}
          <tr className={`${styles.bg_style}`}>
            <th className={styles.rowspan} colSpan="3">BALLAST TANKS</th>
          </tr>
          {["SW BT 1", "SW BT 2", "SW BT 3"].map((tank) => (
            <tr key={tank}>
              <th className={`text-center align-middle ${styles.tank_name}`}>{tank}</th>
              <th>
                {soundings[tank]?.port || "-"} mm <br />
                {volumes[tank]?.port || "-"} m³
              </th>
              <th>
                {soundings[tank]?.starboard || "-"} mm <br />
                {volumes[tank]?.starboard || "-"} m³
              </th>
            </tr>
          ))}
         
          {/* FUEL OIL TANKS */}
          <tr className={`${styles.bg_style}`}>
            <th className={styles.rowspan} colSpan="3">FUEL OIL TANKS</th>
          </tr>
          {["FO Stor. Tk", "FO Day Tk 1", "FO Day Tk 2", "Em.Gen FO Tk"].map((tank) => (
  <tr key={tank}>
    <th className={`text-center align-middle ${styles.tank_name}`}>{tank}</th>
    {(tank === "FO Day Tk 1" || tank === "FO Day Tk 2") ? (
      <>
        <th colSpan="2">
          {soundings[tank]?.port || "-"} mm <br />
          {volumes[tank]?.port || "-"} m³
        </th>
      </>
    ) : (
      <>
        <th>
          {soundings[tank]?.port || "-"} mm <br />
          {volumes[tank]?.port || "-"} m³
        </th>
        <th>
          {soundings[tank]?.starboard || "-"} mm <br />
          {volumes[tank]?.starboard || "-"} m³
        </th>
      </>
    )}
  </tr>
))}
         
          {/* LUB OIL */}
          <tr className={`${styles.bg_style}`}>
            <th className={`${styles.rowspan}`} colSpan="3">LUB OIL</th>
          </tr>
          <tr>
            <th className={`text-center align-middle ${styles.tank_name}`}>LO Tk</th>
            <th colSpan="2" className="text-center align-middle">
              {soundings["LO Tk"]?.port || "-"} mm <br />
              {volumes["LO Tk"]?.port || "-"} m³
            </th>
          </tr>
          
          {/* WATER FRESH */}
          <tr className={`${styles.bg_style}`}>
            <th className={`${styles.rowspan} ${styles.border}`} colSpan="3">FRESH WATER </th>
          </tr>
          <tr>
            <th className={`text-center align-middle ${styles.tank_name}`}>Fresh<br />Water Tk</th>
            <th>
              {soundings["Fresh Water Tk"]?.port || "-"} mm <br />
              {volumes["Fresh Water Tk"]?.port || "-"} m³
            </th>
            <th>
              {soundings["Fresh Water Tk"]?.starboard || "-"} mm <br />
              {volumes["Fresh Water Tk"]?.starboard || "-"} m³
            </th>
          </tr>
        
          {/* MISC TANKS */}
          <tr className={`${styles.bg_style}`}>
            <th className={styles.rowspan} colSpan="3">MISC TANKS</th>
          </tr>
          {["Black Water Tk", "Gray Water Tk", "Dirty Oil Tk", "Bilge Water Tk", "Sea Water Reservoir"].map(
            (tank) => (
              <tr key={tank}>
                <th className={`text-center align-middle ${styles.tank_name}`}>{tank}</th>
                <th colSpan="2" className="text-center align-middle">
                  {soundings[tank]?.port || "-"} mm <br />
                  {volumes[tank]?.port || "-"} m³
                </th>
              </tr>
            )
          )}         

          {/* VOID SPACES */}
          <tr className={`${styles.bg_style}`}>
            <th className={styles.rowspan} colSpan="3">VOID SPACES</th>
          </tr>
          {["Void Space 1", "Void Space 2", "Void Space 3", "Void Space 4", "Void Space 5", "Void Space 6"].map(
            (tank) => (
              <tr key={tank}>
                <th className={`text-center align-middle ${styles.tank_name}`}>{tank}</th>
                {tank === "Void Space 4" ? (
                  <th colSpan="2" className="text-center align-middle">
                    {soundings[tank]?.port || "-"} mm <br />
                    {volumes[tank]?.port || "-"} m³
                  </th>
                ) : (
                  <>
                    <th>
                      {soundings[tank]?.port || "-"} mm <br />
                      {volumes[tank]?.port === "Water in Void Space" ? (
                        <span className={styles.txt_red}>Water in Void Space</span>
                        ) : (
                        `${volumes[tank]?.port || "-"}`
                      )}
                    </th>
                    <th>
                      {soundings[tank]?.starboard || "-"} mm <br />
                      {volumes[tank]?.starboard === "Water in Void Space" ? (
                        <span className={styles.txt_red}>Water in Void Space</span>
                        ) : (
                        `${volumes[tank]?.starboard || "-"}`
                      )}
                    </th>
                  </>
                )}
              </tr>
            )
          )}
         
        </tbody>
      </Table>
    </Container>
  );
};

export default SoundingsReport;
