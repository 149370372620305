import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Container, Table, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { handleGeneratePDF } from '../functions/index';
import styles from '../views/css/checklists.module.css';

const equipmentList = [
    { item: '5L Container', expiry: false },
    { item: 'Anti-Seasickness Tablets, tubes of 25', expiry: true },
    { item: 'Boat Hook', expiry: false },
    { item: 'Bucket', expiry: false },
    { item: 'Buoyant Bailer', expiry: false },
    { item: 'Buoyant Rescue Quoit with 30m Line', expiry: false },    
    { item: 'Can Opener', expiry: false },
    { item: 'Daylight Signaling Mirror', expiry: false },
    { item: 'Drinking water, sealed, 500ml', expiry: true },
    { item: 'Em. Steering Tiller', expiry: false },
    { item: 'Engine Spareparts and Tools, Box', expiry: false },
    { item: 'Food Ration, 10000 KJ bag', expiry: true },
    { item: 'Fire Extinguisher, 2kg, Dry Powder', expiry: true },
    { item: 'First Aid Kit', expiry: true },
    { item: 'Fishing Tackle Kit', expiry: false },
    { item: 'Fuses, 10A', expiry: false },
    { item: 'Graduated Drinking Vessel, 100ml', expiry: false },
    { item: 'Grip Lashing and Bracket', expiry: false },
    { item: 'Hand Flares', expiry: true },
    { item: 'Hatchet', expiry: false },
    { item: 'High-Pressure Hose, 30m', expiry: false },
    { item: 'Jack Knife', expiry: false },
    { item: 'Life Saving Signals, Waterproof Card', expiry: false },
    { item: 'Life Saving Manual', expiry: false },
    { item: 'Manual Pump', expiry: false },
    { item: 'Oar', expiry: false },
    { item: 'Painter Line, 80m', expiry: false },
    { item: 'Radar Reflector', expiry: false },
    { item: 'Rocket Parachute Flares', expiry: true },
    { item: 'Rope Ladder', expiry: false },
    { item: 'Rustproof Dipper with Lanyard', expiry: false },
    { item: 'Sea Anchor with Tripping Line', expiry: false },
    { item: 'Seasickness Bags', expiry: false },
    { item: 'Searchlight', expiry: false },
    { item: 'Smoke Signals', expiry: true },
    { item: 'Spare Batteries (2pcs)', expiry: true },
    { item: 'Spare Bulb (1pc)', expiry: false },
    { item: 'Thermal Protective Aids', expiry: true },
    { item: 'Unit Flexible Hose', expiry: false },
    { item: 'Waterproof Torch', expiry: true },
    { item: 'Whistle', expiry: false }
  ];

const LifeboatChecklist = () => {
  const [formData, setFormData] = useState(() => {
    const savedData = localStorage.getItem('lifeboatChecklist');
    return savedData ? JSON.parse(savedData) : {
      barge: '',
      side: '',
      date: '',
      checklist: equipmentList.map(item => ({ item: item.item, expiry: item.expiry, checked: false, expiryDate: '', quantity: 1 })),
    };
  });

  useEffect(() => {
    localStorage.setItem('lifeboatChecklist', JSON.stringify(formData));
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleChecklistChange = (index, field, value) => {
    setFormData(prev => {
      const updatedChecklist = [...prev.checklist];
      updatedChecklist[index][field] = value;
      return { ...prev, checklist: updatedChecklist };
    });
  };

  const clearForm = () => {
    const resetForm = {
      barge: '',
      side: '',
      date: '',
      checklist: equipmentList.map(item => ({ item: item.item, expiry: item.expiry, checked: false, expiryDate: '', quantity: 1 })),
    };
    setFormData(resetForm);
    localStorage.setItem('lifeboatChecklist', JSON.stringify(resetForm));
  };

  return (
    <Container className={styles.container}>
      <div className="text-end">
        <Link to="/">Go to Check Soundings</Link>
      </div>
      <div id="checkList">
      <h2 className={styles.header}>Lifeboat Equipment Checklist</h2>
      <Form>
        <Row className={styles.row}>
          <Col md={4}>
            <Form.Group controlId="barge">
              <Form.Label className={styles.label}>Barge Name</Form.Label>
              <Form.Select name="barge" value={formData.barge} onChange={handleInputChange} className={styles.select}>
                <option value="" disabled>Select a barge</option>
                <option value="RAWABI JUB 608">RAWABI JUB 608</option>
                <option value="RAWABI JUB 609">RAWABI JUB 609</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="side">
              <Form.Label className={styles.label}>Lifeboat Side</Form.Label>
              <Form.Select name="side" value={formData.side} onChange={handleInputChange} className={styles.select}>
                <option value="" disabled>Select a side</option>
                <option value="port">Port Side</option>
                <option value="starboard">Starboard Side</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="date">
              <Form.Label className={styles.label}>Date</Form.Label>
              <Form.Control
                type="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
                className={styles.dateInput}
              />
            </Form.Group>
          </Col>
        </Row>

        <Table bordered className={styles.table}>
          <thead>
            <tr>             
              <th className='text-center align-middle'>Item</th>
              <th className='text-center align-middle'>Checked</th>             
              <th className='text-center align-middle'>Quantity /<br/>Exp.Date</th>
            </tr>
          </thead>
          <tbody>
            {formData.checklist.map((item, index) => (
              <tr key={index}>              
                <td className='text-center align-middle'>{item.item}</td>
                <td className='text-center align-middle'>
                  <Form.Check
                    type="checkbox"
                    checked={item.checked}
                    onChange={(e) => handleChecklistChange(index, 'checked', e.target.checked)}
                  />
                </td>
               
                <td className='text-center align-middle'>
                    <Form.Select
                        value={item.quantity}
                        onChange={(e) => handleChecklistChange(index, 'quantity', parseInt(e.target.value))}
                    >
                        {Array.from({ length: 901 }, (_, i) => i).map(num => (
                        <option key={num} value={num}>{num}</option>
                        ))}
                    </Form.Select>
                  <br/>
                  {item.expiry && (
                    <Form.Control
                      type="date"
                      value={item.expiryDate}
                      onChange={(e) => handleChecklistChange(index, 'expiryDate', e.target.value)}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Form>
      </div>
      <div className="d-flex justify-content-center gap-3 mt-5 mb-5">
          
          <Button
            onClick={() => handleGeneratePDF("checkList", "LifeBoatInventoryReport.pdf", 50)}
            variant="primary"
            className={styles.downloadButton}
          >
            Download PDF Report
          </Button>
          <Button className={styles.clearButton} variant="danger" onClick={clearForm}>
            Clear Form
          </Button>
        </div>
    </Container>
  );
};

export default LifeboatChecklist;
