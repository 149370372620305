
import { Container, Table, FormControl, InputGroup, Alert, Form } from "react-bootstrap";
import c from "./css/styles.module.css";
import { tankData } from "../models/TankData";
import { calculateVolume } from "../functions";
import { Link } from 'react-router-dom';

const TankSoundingTable = ({
  soundings, setSoundings, volumes, setVolumes, allTanks, reportDate, setReportDate, bargeName, setBargeName
   }) => {

  const voidSpaces = [
    "Void Space 1",
    "Void Space 2",
    "Void Space 3",
    "Void Space 4",
    "Void Space 5",
    "Void Space 6",
  ];

  const unspecified = [
    "LO Tk",
    "Black Water Tk",
    "Gray Water Tk",
    "Dirty Oil Tk",
    "Bilge Water Tk",
    "Sea Water Reservoir",
    "Void Space 4",
    "Em.Gen FO Tk",
    "FO Day Tk 1",
    "FO Day Tk 2",
  ];
  
  

  const handleBargeNameChange = (e) => {
    const value = e.target.value;
    setBargeName(value);
    localStorage.setItem("bargeName", value); // Save to local storage
  };

  const handleReportDateChange = (e) => {
    const value = e.target.value;
    setReportDate(value);
    localStorage.setItem("reportDate", value); // Save to local storage
  };

  const handleInputChange = (e, tank, side) => {
    const input = +e.target.value;

    const updatedSoundings = {
      ...soundings,
      [tank]: {
        ...soundings[tank],
        [side]: input,
      },
    };

    setSoundings(updatedSoundings);

    const numericSounding = parseFloat(input);

    // Void Spaces Logic
    if (voidSpaces.includes(tank)) {
      const updatedVolumes = {
        ...volumes,
        [tank]: {
          ...volumes[tank],
          [side]: numericSounding === 0 ? "Empty" : "Water in Void Space",
        },
      };
      setVolumes(updatedVolumes);
      localStorage.setItem("tankSoundings", JSON.stringify(updatedSoundings));
      localStorage.setItem("tankVolumes", JSON.stringify(updatedVolumes));
      return;
    }

    // Non-Void Tanks Logic
    if (numericSounding === 0) {
      const updatedVolumes = {
        ...volumes,
        [tank]: {
          ...volumes[tank],
          [side]: "Empty",
        },
      };
      setVolumes(updatedVolumes);
      localStorage.setItem("tankSoundings", JSON.stringify(updatedSoundings));
      localStorage.setItem("tankVolumes", JSON.stringify(updatedVolumes));
      return;
    }

    const sideData = tankData[tank] && tankData[tank][side] ? tankData[tank][side] : [];
    const updatedVolumes = {
      ...volumes,
      [tank]: {
        ...volumes[tank],
        [side]: calculateVolume(numericSounding, sideData),
      },
    };
    setVolumes(updatedVolumes);

    localStorage.setItem("tankSoundings", JSON.stringify(updatedSoundings));
    localStorage.setItem("tankVolumes", JSON.stringify(updatedVolumes));
  };

  return (
    <Container className="mt-4">
      <div className="text-end">
          <Link to="/checklists">Go to Check Lists</Link>
      </div>
    <h1 className="text-center">Tank Sounding Table</h1>

      <div className={`d-flex justify-content-between mb-3 ${c.top_input_container}`}>
      <InputGroup className={c.top_input}>
        <InputGroup.Text>Barge Name:</InputGroup.Text>
        <Form.Select
          value={bargeName}
          onChange={handleBargeNameChange}
        >
          <option value="RAWABI JUB 608">RAWABI JUB 608</option>
          <option value="RAWABI JUB 609">RAWABI JUB 609</option>
        </Form.Select>
      </InputGroup>

        <InputGroup className={c.top_input}>
          <InputGroup.Text>Report Date:</InputGroup.Text>
          <FormControl
            type="date"
            value={reportDate}
            onChange={handleReportDateChange}
          />
        </InputGroup>
      </div>
     

      <Table bordered className={c.sounding_table}>
        <thead>
          <tr className={c.width}>
            <th className="text-center align-middle">Tank Name</th>
            <th>Port Side <br />(mm & m³)</th>
            <th>Starboard Side <br />(mm & m³)</th>
          </tr>
        </thead>
        <tbody>
          {allTanks.map((tank) => (
            <tr key={tank} className={c.table_td}>
              <td className={`text-center align-middle ${c.table_td}`}>{tank}</td>
              <td className={c.table_td}>
                <InputGroup className="mb-2">               
                  <FormControl
                    type="number"
                    placeholder="Enter sounding, mm"
                    value={soundings[tank].port}
                    onChange={(e) => handleInputChange(e, tank, "port")}
                    className={c.input}
                  /><div className={c.display_mm}> {soundings[tank].port !== undefined && soundings[tank].port >= 0 && 'mm'}</div>
                </InputGroup>
                <Alert
                  variant={
                    volumes[tank].port === "Water in Void Space"
                      ? "danger"
                      : volumes[tank].port === "Out of range" 
                      ? "danger"
                      : volumes[tank].port === "Empty"
                      ? "info"
                      : volumes[tank].port
                      ? "success"
                      : "secondary"
                  }
                  className="p-2"
                >
                  {volumes[tank].port === "Water in Void Space"
                    ? "Water in Void Space"
                    : volumes[tank].port === "Out of range" ?
                    "Out of range"                    
                    : volumes[tank].port === "Empty"
                    ? "Empty"
                    : volumes[tank].port !== ""
                    ? `${volumes[tank].port} m³`
                    : "-"}
                </Alert>
              </td>
              <td className={c.table_td}>
                {unspecified.includes(tank) ? (
                  <div className="text-center align-middle">—</div>
                ) : (
                  <>
                    <InputGroup className="mb-2">
                      <FormControl
                        type="number"
                        placeholder="Enter sounding, mm"
                        value={soundings[tank].starboard}
                        onChange={(e) => handleInputChange(e, tank, "starboard")}
                        className={c.input}
                      /><div className={c.display_mm}> {soundings[tank].starboard && soundings[tank].starboard >= 0 && 'mm'}</div>
                    </InputGroup>
                    <Alert
                  variant={
                    volumes[tank].starboard === "Water in Void Space"
                      ? "danger"
                      : volumes[tank].starboard === "Out of range" 
                      ? "danger"
                      : volumes[tank].starboard === "Empty"
                      ? "info"
                      : volumes[tank].starboard
                      ? "success"
                      : "secondary"
                  }
                  className="p-2"
                >
                  {volumes[tank].starboard === "Water in Void Space"
                    ? "Water in Void Space"
                    : volumes[tank].starboard === "Out of range" ?
                    "Out of range"                    
                    : volumes[tank].starboard === "Empty"
                    ? "Empty"
                    : volumes[tank].starboard !== ""
                    ? `${volumes[tank].starboard} m³`
                    : "-"}
                </Alert>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* <div className="text-center mt-4">
        <Button
          onClick={() => handleGeneratePDF("sounding-table")}
          variant="primary"
        >
          Download PDF Report
        </Button>
      </div>
      <br /> */}
    </Container>
  );
};

export default TankSoundingTable;
