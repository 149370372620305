import React, { useState, useEffect } from 'react'

import Soundings from '../views/Soundings'
import { Button } from "react-bootstrap";
import SoundingsReport from '../views/SoundingsReport';
import { handleGeneratePDF } from '../functions/index'
import c from '../views/css/styles.module.css'



const TankSoundings = () => {

    const [displayReport, setDisplayReport] = useState(false)

    const allTanks = [
      "SW BT 1",
      "SW BT 2",
      "SW BT 3",
      "FO Stor. Tk",
      "FO Day Tk 1",
      "FO Day Tk 2",
      "Em.Gen FO Tk",
      "LO Tk",
      "Fresh Water Tk",
      "Black Water Tk",
      "Gray Water Tk",
      "Dirty Oil Tk",
      "Bilge Water Tk",
      "Sea Water Reservoir",
      "Void Space 1",
      "Void Space 2",
      "Void Space 3",
      "Void Space 4",
      "Void Space 5",
      "Void Space 6",
    ];
  
    const initialState = Object.fromEntries(
      allTanks.map((tank) => [tank, { port: "", starboard: "" }])
    );
  
    const [soundings, setSoundings] = useState(initialState);
    const [volumes, setVolumes] = useState(initialState);
    const [bargeName, setBargeName] = useState(localStorage.getItem("bargeName") || "");
    const [reportDate, setReportDate] = useState(localStorage.getItem("reportDate") || "");
  
    useEffect(() => {
      const storedSoundings = localStorage.getItem("tankSoundings");
      const storedVolumes = localStorage.getItem("tankVolumes");
  
      if (storedSoundings) {
        setSoundings(JSON.parse(storedSoundings));
      }
      if (storedVolumes) {
        setVolumes(JSON.parse(storedVolumes));
      }
    }, []);

    const handleClearForm = () => {
      // Clear local storage
      localStorage.removeItem("tankSoundings");
      localStorage.removeItem("tankVolumes");
      localStorage.removeItem("bargeName");
      localStorage.removeItem("reportDate");
    
      // Reset state values (you can replace `initialState` with your defined default states)
      setSoundings(initialState);
      setVolumes(initialState);
    };


  return (
    <div>
        {displayReport ? <SoundingsReport
                            soundings={soundings}
                            volumes={volumes}
                          /> 
                         : 
                          <Soundings 
                            soundings={soundings}
                            setSoundings={setSoundings}
                            volumes={volumes}
                            setVolumes={setVolumes}
                            allTanks={allTanks}
                            bargeName={bargeName} 
                            setBargeName={setBargeName}
                            reportDate={reportDate}
                            setReportDate={setReportDate}
                          />
        }
     <div className={c.lower_btn}>
        {displayReport ?
           <Button
            onClick={() => handleGeneratePDF("sounding-table", "TankSoundingReport.pdf", 120)}
            variant="primary"
            className='mr-3'
          >
            Download PDF Report
          </Button> : 
          <Button variant='outline-danger' onClick={() => handleClearForm()}>
            Clear the Form
          </Button>
        }
       
        <Button 
          onClick={() => setDisplayReport(!displayReport)} 
          variant={!displayReport ? "primary" : "outline-secondary"}
          className='ml-3'
          >
          {displayReport ? "Back to Soundings" : "Show Report"}
        </Button>
      </div><br/><br/>
    </div>
  )
}

export default TankSoundings