
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TankSoundings from './controllers/TankSoundings';
 import CheckLists from './controllers/CheckLists';
// import About from './controllers/About';

function App() {
  return (
  
      <Router>
      <div className="App">
        {/* Route Definitions */}
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<TankSoundings />} />
          <Route path="/checklists" element={<CheckLists />} />
        </Routes>
      </div>
    </Router>
   
  );
}

export default App;



